import React from "react";
import { Card } from "../Card";
import { AtcsccAnnouncementEvent } from "../../../types";
import "./atcsccAnnouncementsCard.scss";

export interface AtcsccAnnouncementsCardProps {
  identifier: string;
  events: AtcsccAnnouncementEvent[];
}
const AtcsccAnnouncementsCard: React.FC<AtcsccAnnouncementsCardProps> = (props) => (
  <Card identifier={props.identifier}>
    <table>
      <tbody>
        {props.events.map((event, index) => {
          return (
            <tr
              className="atcscc-table-row"
              data-testid={`${props.identifier}-table-row-${index}`}
              key={`${props.identifier}-row-${index}`}
            >
              <td className="atcscc-table-element atcscc-event">
                <p className="atcscc-event-text">{event.event}</p>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </Card>
);

export default AtcsccAnnouncementsCard;
