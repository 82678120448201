import React from "react";
import ExternalLink from "../ExternalLink";

export const HeaderLinks: React.FC = () => {
  return (
    <>
      <ExternalLink
        className="header-external-link"
        href="https://www.fly.faa.gov/adv/advAdvisoryForm.jsp"
        metricLog={"Link Header Advisories"}
      >
        Advisories
      </ExternalLink>
      <ExternalLink
        className="header-external-link"
        href="https://www.fly.faa.gov/aadc/"
        metricLog={"Link Header Airport Demand"}
      >
        Airport Demand
      </ExternalLink>
      <ExternalLink
        className="header-external-link"
        href="https://www.fly.faa.gov/edct/jsp/edctLookUp.jsp"
        metricLog={"Link Header EDCT"}
      >
        EDCT
      </ExternalLink>
      <ExternalLink
        className="header-external-link"
        href="https://notams.aim.faa.gov/notamSearch/nsapp.html#/"
        metricLog={"Link Header NOTAMS"}
      >
        NOTAMS
      </ExternalLink>
      <ExternalLink
        className="header-external-link"
        href="https://www.fly.faa.gov/ratreader/jsp/index.jsp"
        metricLog={"Link Header Reroutes"}
      >
        Reroutes
      </ExternalLink>
      <ExternalLink
        className="header-external-link"
        href="https://www.fly.faa.gov/current_restrictions/jsp/index.jsp"
        metricLog={"Link Header Restrictions"}
      >
        Restrictions
      </ExternalLink>
      <ExternalLink className="header-external-link" href="https://rvr.data.faa.gov/" metricLog={"Link Header RVR"}>
        RVR
      </ExternalLink>
      <ExternalLink
        className="header-external-link"
        href="https://aviationweather.gov/gfa/#obs"
        metricLog={"Link Header Weather"}
      >
        Weather
      </ExternalLink>
    </>
  );
};
