import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./LegendModal.scss";
import LegendButton from "../../static/Mobile-legend-button.png";

export default () => (
  <div className="legend-popup-button-div">
    <Popup
      trigger={
        <button className="legend-button">
          {" "}
          <img className="legend-button-image" src={LegendButton} alt="Button to display pop-up legend" />{" "}
        </button>
      }
      position="bottom left"
    >
      <div className="legend-popup">
        <img className="legend" src="/nasMapSymbols/Map_Legend_Mobile_View.png" alt="legend" />
      </div>
    </Popup>
  </div>
);
