import React, { useContext, useEffect, useRef, useState } from "react";
import { loadModules } from "esri-loader";
import "./mapViewCard.scss";
import {
  AirportEvent,
  ArtccBoundary,
  Circle as CircleType,
  EnrouteEvent,
  FcaAirport as FcaAirportType,
  FcaArtcc as FcaArtccType,
  Line as LineType,
  Polygon as PolygonType,
  UpdatableEntity,
} from "../../../types";
import restHelpers, { API } from "../../../helpers/restHelpers";
import { isEqual, omit } from "lodash";
import { getAndApplyLogic } from "../../../helpers/stateHelpers";
import { isMobile, isTablet } from "react-device-detect";
import { formatDateToDD, toggleZuluAndLocalTime } from "../../../helpers/dateHelper";
import { LocalTimeContext } from "../../../AppContext";

export interface MapViewCardProps {
  showArtccBoundaries: boolean;
}

const loadEsriModules = () =>
  loadModules(
    [
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/geometry/Polygon",
      "esri/geometry/Circle",
      "esri/geometry/Polyline",
      "esri/geometry/Point",
    ],
    {
      css: true,
    }
  );

let refreshCount = 0;
let isRefreshMapModule = false;

const ARRIVAL_DELAY = "Arrival Delay";
const DEPARTURE_DELAY = "Departure Delay";
const AIRPORT_CLOSURE = "Airport Closure";
const GROUND_DELAY = "Ground Delay";
const GROUND_STOP = "Ground Stop";

const drawRelevantAirportNames = (Graphic: any, view: any) => {
  restHelpers.get(API.AirportEvents).then((airportEvents: AirportEvent[]) => {
    airportEvents.forEach((airportEvent) => {
      if (airportEvent.latitude !== null || airportEvent.longitude !== null) {
        const labelLocation = {
          type: "point",
          latitude: parseFloat(airportEvent.latitude as string),
          longitude: parseFloat(airportEvent.longitude as string),
        };

        const relevantAirportIdlabelGraphic = new Graphic({
          geometry: labelLocation,
          symbol: { type: "text", text: airportEvent.airportId, color: "black", yoffset: 15, font: { size: 12 } },
        });

        view.graphics.add(relevantAirportIdlabelGraphic);
      }
    });
  });
};

const drawAirportEventSymbols = (Graphic: any, view: any, isLocalTime: boolean) => {
  let symbolUrl = "/nasMapSymbols/Default.png";

  restHelpers.get(API.AirportEvents).then((airportEvents: AirportEvent[]) => {
    airportEvents.forEach((airportEvent) => {
      if (airportEvent.latitude !== null || airportEvent.longitude !== null) {
        const labelLocation = {
          type: "point",
          latitude: parseFloat(airportEvent.latitude as string),
          longitude: parseFloat(airportEvent.longitude as string),
        };

        let htmlContent = "";
        const airportMessage = (
          airportEvent: AirportEvent,
          eventType: any,
          startTime?: any,
          endTime?: any,
          severeType?: any
        ) => {
          let htmlValue: string | undefined = "";
          if (eventType === ARRIVAL_DELAY || eventType === DEPARTURE_DELAY) {
            htmlValue =
              "<div class='map-card-block'><div class='map-card-content'><span class='map-event-type " +
              severeType +
              "-severe-map'>" +
              eventType +
              "</span><span class='map-event-time-block'>" +
              "<span>Updated </span><span class='map-event-time'>" +
              formatDateToDD(startTime, isLocalTime) +
              "/</span>" +
              "<span class='map-event-time'>" +
              toggleZuluAndLocalTime(startTime, isLocalTime) +
              "</span>" +
              "</span></div>";

            if (eventType === ARRIVAL_DELAY) {
              htmlValue =
                htmlValue +
                "<div class='map-card-description'><span>Arrivals to " +
                airportEvent.airportLongName +
                " are delayed an avg. " +
                airportEvent.arrivalDelay?.averageDelay +
                " mins. (and " +
                airportEvent.arrivalDelay?.trend +
                ") due to " +
                airportEvent.arrivalDelay?.reason.toLowerCase() +
                ".</span></div></div>";
            } else if (eventType === DEPARTURE_DELAY) {
              htmlValue =
                htmlValue +
                "<div class='map-card-description'><span>Departures from " +
                airportEvent.airportLongName +
                " are delayed an avg. " +
                airportEvent.departureDelay?.averageDelay +
                " mins. (and " +
                airportEvent.departureDelay?.trend +
                ") due to " +
                airportEvent.departureDelay?.reason.toLowerCase() +
                ".</span></div></div>";
            }
          } else if (eventType === "Deicing") {
            htmlValue =
              "<div class='map-card-block'><div class='map-card-content'><span class='map-event-type " +
              severeType +
              "-severe-map'>" +
              eventType +
              "</span><span class='map-event-time-block'>" +
              "<span>Started </span><span class='map-event-time'>" +
              formatDateToDD(startTime, isLocalTime) +
              "/</span>" +
              "<span class='map-event-time'>" +
              toggleZuluAndLocalTime(startTime, isLocalTime) +
              "</span>" +
              "</span></div>" +
              "<div class='map-card-description'><span>Aircraft departing from " +
              airportEvent.airportLongName +
              " are being sprayed with deicing fluid to remove snow and ice" +
              ".</span></div></div>";
          } else {
            htmlValue =
              "<div class='map-card-block'><div class='map-card-content'><span class='map-event-type " +
              severeType +
              "-severe-map'>" +
              eventType +
              "</span><span class='map-event-time-block'>" +
              "<span class='map-event-time'>" +
              formatDateToDD(startTime, isLocalTime) +
              "/</span>" +
              "<span class='map-event-time'>" +
              toggleZuluAndLocalTime(startTime, isLocalTime) +
              "</span>" +
              "<span class='map-event-time'>—" +
              formatDateToDD(endTime, isLocalTime) +
              "/</span>" +
              "<span class='map-event-time'>" +
              toggleZuluAndLocalTime(endTime, isLocalTime) +
              "</span>" +
              "</span></div>";

            if (eventType === GROUND_STOP) {
              htmlValue =
                htmlValue +
                "<div class='map-card-description'><span>Departures to " +
                airportEvent.airportLongName +
                " are grounded due to " +
                airportEvent.groundStop?.impactingCondition?.toLowerCase() +
                ".</span></div></div>";
            } else if (eventType === GROUND_DELAY) {
              htmlValue =
                htmlValue +
                "<div class='map-card-description'><span>Departures to " +
                airportEvent.airportLongName +
                " are delayed an avg. " +
                airportEvent.groundDelay?.avgDelay +
                " mins. due to " +
                airportEvent.groundDelay?.impactingCondition?.toLowerCase() +
                ".</span></div></div>";
            } else if (eventType === AIRPORT_CLOSURE) {
              htmlValue =
                htmlValue +
                "<div class='map-card-description'><span>" +
                airportEvent.airportLongName +
                " is closed " +
                airportEvent.airportClosure?.text +
                "</span></div></div>";
            } else if (eventType === "Free Form") {
              htmlValue =
              "<div class='map-card-block'><div class='map-card-content'><span class='map-event-type " +
              severeType +
              "-severe-map'>" +
              AIRPORT_CLOSURE +
              "</span><span class='map-event-time-block'>" +
              "<span>Updated </span><span class='map-event-time'>" +
              formatDateToDD(startTime, isLocalTime) +
              "/</span>" +
              "<span class='map-event-time'>" +
              toggleZuluAndLocalTime(startTime, isLocalTime) +
              "</span>" +
              "</span></div>" +
                "<div class='map-card-description'><span>" +
                airportEvent.airportLongName +
                " is closed " +
                airportEvent.freeForm?.text +
                "</span></div></div>";
            }
          }
          return htmlValue;
        };

        //SINGLE CONDITION
        if (Boolean(airportEvent.airportClosure)) {
          symbolUrl = "/nasMapSymbols/AirportClosure.png";
          htmlContent =
            htmlContent +
            airportMessage(
              airportEvent,
              AIRPORT_CLOSURE,
              airportEvent.airportClosure?.startTime,
              airportEvent.airportClosure?.endTime,
              "high"
            );
        }
        if (Boolean(airportEvent.freeForm)) {
          symbolUrl = "/nasMapSymbols/AirportClosure.png";
          htmlContent =
            htmlContent +
            airportMessage(
              airportEvent,
              "Free Form",
              airportEvent.freeForm?.startTime,
              airportEvent.freeForm?.endTime,
              "high"
            );
        }
        if (Boolean(airportEvent.groundStop)) {
          symbolUrl = "/nasMapSymbols/GroundStop.png";
          htmlContent =
            htmlContent +
            airportMessage(
              airportEvent,
              GROUND_STOP,
              airportEvent.groundStop?.startTime,
              airportEvent.groundStop?.endTime,
              "high"
            );
        }
        if (Boolean(airportEvent.groundDelay)) {
          symbolUrl = "/nasMapSymbols/GroundDelay.png";
          htmlContent =
            htmlContent +
            airportMessage(
              airportEvent,
              GROUND_DELAY,
              airportEvent.groundDelay?.startTime,
              airportEvent.groundDelay?.endTime,
              "medium"
            );
        }
        if (Boolean(airportEvent.arrivalDelay)) {
          symbolUrl = "/nasMapSymbols/ArrivalDelay.png";
          htmlContent =
            htmlContent +
            airportMessage(airportEvent, ARRIVAL_DELAY, airportEvent.arrivalDelay?.updateTime, undefined, "low");
        }
        if (Boolean(airportEvent.departureDelay)) {
          symbolUrl = "/nasMapSymbols/DepartureDelay.png";
          htmlContent =
            htmlContent +
            airportMessage(airportEvent, DEPARTURE_DELAY, airportEvent.departureDelay?.updateTime, undefined, "low");
        }
        if (Boolean(airportEvent.deicing)) {
          symbolUrl = "/nasMapSymbols/Deicing.png";
          htmlContent = htmlContent + airportMessage(airportEvent, "Deicing", airportEvent.deicing?.eventTime, "low");
        }

        // //TWO CONDITIONS
        if (Boolean(airportEvent.groundDelay) && airportEvent.groundStop) {
          symbolUrl = "/nasMapSymbols/GDP_GS.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.deicing) {
          symbolUrl = "/nasMapSymbols/GDP_DICE.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/GDP_AD.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/GDP_DD.png";
        }
        if (Boolean(airportEvent.groundDelay) && (airportEvent.airportClosure || airportEvent.freeForm)) {
          symbolUrl = "/nasMapSymbols/GDP_AC.png";
        }
        if (Boolean(airportEvent.groundStop) && airportEvent.deicing) {
          symbolUrl = "/nasMapSymbols/GS_DICE.png";
        }
        if (Boolean(airportEvent.groundStop) && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/GS_AD.png";
        }
        if (Boolean(airportEvent.groundStop) && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/GS_DD.png";
        }
        if (Boolean(airportEvent.groundStop) && (airportEvent.airportClosure || airportEvent.freeForm)) {
          symbolUrl = "/nasMapSymbols/GS_AC.png";
        }
        if (Boolean(airportEvent.deicing) && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/DICE_AD.png";
        }
        if (Boolean(airportEvent.deicing) && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/DICE_DD.png";
        }
        if (Boolean(airportEvent.deicing) && (airportEvent.airportClosure || airportEvent.freeForm)) {
          symbolUrl = "/nasMapSymbols/DICE_AC.png";
        }
        if (Boolean(airportEvent.airportClosure || airportEvent.freeForm) && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/AC_AD.png";
        }
        if (Boolean(airportEvent.airportClosure || airportEvent.freeForm) && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/AC_DD.png";
        }
        if (Boolean(airportEvent.arrivalDelay) && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/AD_DD.png";
        }

        //THREE CONDITIONS
        if (Boolean(airportEvent.groundDelay) && airportEvent.groundStop && airportEvent.deicing) {
          symbolUrl = "/nasMapSymbols/GDP_GS_DICE.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.groundStop && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/GDP_GS_AD.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.groundStop && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/GDP_GS_DD.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.groundStop && (airportEvent.airportClosure || airportEvent.freeForm)) {
          symbolUrl = "/nasMapSymbols/GDP_GS_AC.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.deicing && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/GDP_DICE_AD.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.deicing && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/GDP_DICE_DD.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.deicing && (airportEvent.airportClosure || airportEvent.freeForm)) {
          symbolUrl = "/nasMapSymbols/GDP_DICE_AC.png";
        }
        if (Boolean(airportEvent.groundDelay) && (airportEvent.airportClosure || airportEvent.freeForm) && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/GDP_AC_AD.png";
        }
        if (Boolean(airportEvent.groundDelay) && (airportEvent.airportClosure || airportEvent.freeForm) && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/GDP_AC_DD.png";
        }
        if (Boolean(airportEvent.groundStop) && airportEvent.deicing && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/GS_DICE_AD.png";
        }
        if (Boolean(airportEvent.groundStop) && airportEvent.deicing && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/GS_DICE_DD.png";
        }
        if (Boolean(airportEvent.groundStop) && airportEvent.deicing && (airportEvent.airportClosure || airportEvent.freeForm)) {
          symbolUrl = "/nasMapSymbols/GS_DICE_AC.png";
        }
        if (Boolean(airportEvent.groundStop) && (airportEvent.airportClosure || airportEvent.freeForm) && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/GS_AC_AD.png";
        }
        if (Boolean(airportEvent.groundStop) && (airportEvent.airportClosure || airportEvent.freeForm) && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/GS_AC_DD.png";
        }
        if (Boolean(airportEvent.deicing) && (airportEvent.airportClosure || airportEvent.freeForm) && airportEvent.arrivalDelay) {
          symbolUrl = "/nasMapSymbols/DICE_AC_AD.png";
        }
        if (Boolean(airportEvent.deicing) && (airportEvent.airportClosure || airportEvent.freeForm) && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/DICE_AC_DD.png";
        }
        if (Boolean(airportEvent.airportClosure || airportEvent.freeForm) && airportEvent.arrivalDelay && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/AD_DD_AC.png";
        }
        if (Boolean(airportEvent.groundDelay) && airportEvent.arrivalDelay && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/GDP_DD_AD.png";
        }
        if (Boolean(airportEvent.groundStop) && airportEvent.arrivalDelay && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/GS_AD_DD.png";
        }
        if (Boolean(airportEvent.deicing) && airportEvent.arrivalDelay && airportEvent.departureDelay) {
          symbolUrl = "/nasMapSymbols/AD_DD_DICE.png";
        }

        //FOUR CONDITIONS
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundDelay &&
          airportEvent.groundStop &&
          airportEvent.arrivalDelay
        ) {
          symbolUrl = "/nasMapSymbols/AC_GS_GDP_AD.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundDelay &&
          airportEvent.groundStop &&
          airportEvent.departureDelay
        ) {
          symbolUrl = "/nasMapSymbols/AC_GDP_GS_DD.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundDelay &&
          airportEvent.groundStop &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/AC_GS_GDP_DICE.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundDelay &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay
        ) {
          symbolUrl = "/nasMapSymbols/AC_GDP_AD_DD.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundDelay &&
          airportEvent.arrivalDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/AC_GDP_AD_DICE.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundDelay &&
          airportEvent.departureDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/AC_GDP_DD_DICE.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundStop &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay
        ) {
          symbolUrl = "/nasMapSymbols/AC_GS_AD_DD.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundStop &&
          airportEvent.arrivalDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/AC_GS_AD_DICE.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundStop &&
          airportEvent.departureDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/AC_GS_DD_DICE.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/AC_AD_DD_DICE.png";
        }
        if (
          Boolean(airportEvent.groundDelay) &&
          airportEvent.groundStop &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay
        ) {
          symbolUrl = "/nasMapSymbols/GDP_GS_DD_AD.png";
        }
        if (
          Boolean(airportEvent.groundDelay) &&
          airportEvent.groundStop &&
          airportEvent.arrivalDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/GDP_GS_AD_DICE.png";
        }
        if (
          Boolean(airportEvent.groundDelay) &&
          airportEvent.groundStop &&
          airportEvent.deicing &&
          airportEvent.departureDelay
        ) {
          symbolUrl = "/nasMapSymbols/GDP_GS_DD_DICE.png";
        }
        if (
          Boolean(airportEvent.groundDelay) &&
          airportEvent.deicing &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay
        ) {
          symbolUrl = "/nasMapSymbols/GDP_AD_DD_DICE.png";
        }
        if (
          Boolean(airportEvent.groundStop) &&
          airportEvent.deicing &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay
        ) {
          symbolUrl = "/nasMapSymbols/GS_DD_AD_DICE.png";
        }

        //FIVE CONDITIONS
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundDelay &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/GDP_AD_DD_DICE_AC.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundStop &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/GS_AD_DD_DICE_AC.png";
        }
        if (
          Boolean(airportEvent.groundDelay) &&
          airportEvent.groundStop &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/GS_GDP_DICE_AD_DD.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundStop &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay &&
          airportEvent.groundDelay
        ) {
          symbolUrl = "/nasMapSymbols/AC_GDP_GS_AD_DD.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundStop &&
          airportEvent.arrivalDelay &&
          airportEvent.groundDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/AC_GDP_GS_AD_DICE.png";
        }
        if (
          Boolean(airportEvent.airportClosure || airportEvent.freeForm) &&
          airportEvent.groundStop &&
          airportEvent.groundDelay &&
          airportEvent.departureDelay &&
          airportEvent.deicing
        ) {
          symbolUrl = "/nasMapSymbols/AC_GDP_GS_DD_DICE.png";
        }

        //SIX CONDITIONS
        if (
          Boolean(airportEvent.groundStop) &&
          airportEvent.groundDelay &&
          airportEvent.arrivalDelay &&
          airportEvent.departureDelay &&
          airportEvent.deicing &&
          (airportEvent.airportClosure || airportEvent.freeForm)
        ) {
          symbolUrl = "/nasMapSymbols/GS_GDP_AD_DD_DICE_AC.png";
        }

        const popupEventCards = {
          title: "<p class='map-card-title'>" + airportEvent.airportId + "</p>",
          content: htmlContent,
        };

        const labelGraphic = new Graphic({
          geometry: labelLocation,
          symbol: {
            type: "picture-marker",
            width: "30px",
            height: "30px",
            url: `${process.env.PUBLIC_URL + symbolUrl}`,
          },
          popupTemplate: popupEventCards,
        });

        view.graphics.add(labelGraphic);
      }
    });
  });
};

const drawAirspaceFlowProgram = (
  enrouteEvents: EnrouteEvent,
  Graphic: any,
  view: any,
  Polygon: any,
  Circle: any,
  Polyline: any,
  Point: any,
  isLocalTime: any
) => {
  let createdShape;

  if (Boolean(enrouteEvents.polygon)) {
    const polygonPoints = (enrouteEvents.polygon as PolygonType).points.point;
    const rings = polygonPoints.map((point) => {
      return [parseFloat(point.longitude), parseFloat(point.latitude)];
    });
    rings.push([parseFloat(polygonPoints[0].longitude), parseFloat(polygonPoints[0].latitude)]);

    createdShape = new Polygon({
      rings: rings,
    });
  }

  if (Boolean(enrouteEvents.circle)) {
    const passedInCircle = enrouteEvents.circle as CircleType;
    createdShape = new Circle({
      center: {
        latitude: parseFloat(passedInCircle.center.latitude),
        longitude: parseFloat(passedInCircle.center.longitude),
      },
      radius: parseInt(passedInCircle.radius),
      radiusUnit: "nautical-miles",
    });
  }

  if (Boolean(enrouteEvents.line)) {
    const linePoints = (enrouteEvents.line as LineType).points.point;
    const paths = linePoints.map((point) => {
      return [parseFloat(point.longitude), parseFloat(point.latitude)];
    });

    createdShape = new Polyline({ paths });
  }

  let symbol;
  let afpDescription;
  if (Boolean(enrouteEvents.fcaAirport && enrouteEvents.fcaAirport.point.latitude !== null)) {
    const fcaAirportPoints = (enrouteEvents.fcaAirport as FcaAirportType).point;

    createdShape = new Point({
      latitude: fcaAirportPoints.latitude,
      longitude: fcaAirportPoints.longitude,
    });

    symbol = {
      type: "picture-marker",
      width: "20px",
      height: "20px",
      url: "/nasMapSymbols/Nas_Fca_Airport.png",
    };

    afpDescription =
      "<div class='map-card-description'><span>Flights operating at " +
      enrouteEvents.fcaAirport?.fcaAirportName +
      " will be delayed an avg. ";
  } else {
    symbol = { type: "simple-line", color: [184, 94, 11], width: 2 };
    afpDescription =
      "<div class='map-card-description'><span>Flights crossing the Flow Constrained Area (FCA) will be delayed an avg. ";
  }

  if (Boolean(enrouteEvents.fcaArtcc)) {
    const polygonPoints = (enrouteEvents.fcaArtcc as FcaArtccType).points.point;
    const rings = polygonPoints.map((point) => {
      return [parseFloat(point.longitude), parseFloat(point.latitude)];
    });
    rings.push([parseFloat(polygonPoints[0].longitude), parseFloat(polygonPoints[0].latitude)]);

    createdShape = new Polygon({
      rings: rings,
    });

    afpDescription =
      "<div class='map-card-description'><span>Flights entering " +
      enrouteEvents.fcaArtcc?.fcaArtccName +
      " ARTCC will be delayed an avg. ";
  }

  const afpHtmlValue =
    "<div class='map-card-content'><span class='map-event-type low-severe-map'>Airspace Flow Program</span><span class='map-event-time-block'>" +
    "<span class='map-event-time'>" +
    formatDateToDD(enrouteEvents.airspaceFlowProgram.startTime, isLocalTime) +
    "/</span>" +
    "<span class='map-event-time'>" +
    toggleZuluAndLocalTime(enrouteEvents.airspaceFlowProgram.startTime, isLocalTime) +
    "</span>" +
    "<span class='map-event-time'>—" +
    formatDateToDD(enrouteEvents.airspaceFlowProgram.endTime, isLocalTime) +
    "/</span>" +
    "<span class='map-event-time'>" +
    toggleZuluAndLocalTime(enrouteEvents.airspaceFlowProgram.endTime, isLocalTime) +
    "</span>" +
    "</span></div>" +
    afpDescription +
    Math.round(enrouteEvents.airspaceFlowProgram.avgDelay) +
    " mins. or routed around due to " +
    enrouteEvents.airspaceFlowProgram.impactingCondition.toLowerCase() +
    ".</span></div>";

  const popupAfpCard = {
    title: "<p class='map-card-title'>" + enrouteEvents.airspaceFlowProgram.afpName + "</p>",
    content: afpHtmlValue,
  };

  const graphic = new Graphic({
    geometry: createdShape,
    symbol: symbol,
    popupTemplate: popupAfpCard,
  });

  view.graphics.add(graphic);
};

const drawRelevantAfpName = (enrouteEvents: EnrouteEvent, Graphic: any, view: any) => {
  let createdShape;

  if (Boolean(enrouteEvents.polygon)) {
    const polygonPoints = (enrouteEvents.polygon as PolygonType).points.point;
    createdShape = { type: "point", latitude: polygonPoints[0].latitude, longitude: polygonPoints[0].longitude };
  }

  if (Boolean(enrouteEvents.circle)) {
    createdShape = {
      type: "point",
      latitude: parseFloat(enrouteEvents.circle?.center.latitude as string),
      longitude: parseFloat(enrouteEvents.circle?.center.longitude as string),
    };
  }

  if (Boolean(enrouteEvents.line)) {
    const linePoints = (enrouteEvents.line as LineType).points.point;
    createdShape = {
      type: "point",
      latitude: linePoints[0].latitude,
      longitude: linePoints[0].longitude,
    };
  }

  if (Boolean(enrouteEvents.fcaAirport && enrouteEvents.fcaAirport.point.latitude !== null)) {
    const fcaAirportPoints = (enrouteEvents.fcaAirport as FcaAirportType).point;
    createdShape = {
      type: "point",
      latitude: fcaAirportPoints.latitude,
      longitude: fcaAirportPoints.longitude,
    };
  }

  if (Boolean(enrouteEvents.fcaArtcc)) {
    const fcaArtccPoints = (enrouteEvents.fcaArtcc as FcaArtccType).points.point;
    createdShape = {
      type: "point",
      latitude: fcaArtccPoints[0].latitude,
      longitude: fcaArtccPoints[0].longitude,
    };
  }

  const graphic = new Graphic({
    geometry: createdShape,
    symbol: {
      type: "text",
      text: enrouteEvents.airspaceFlowProgram.afpName,
      color: "black",
      font: {
        size: 12,
      },
    },
  });

  view.graphics.add(graphic);
};

const drawARTCCBoundaries = (Polygon: any, Graphic: any, view: any) => {
  restHelpers.get(API.ArtccBoundaries).then((boundaries: ArtccBoundary[]) => {
    boundaries.forEach((boundary) => {
      const rings = boundary.points.map((point: any) => {
        return [parseFloat(point.longitude), parseFloat(point.latitude)];
      });
      rings.push([parseFloat(boundary.points[0].longitude), parseFloat(boundary.points[0].latitude)]);
      const createdShape = new Polygon({
        rings: rings,
      });

      const artccBoundaryGraphic = new Graphic({
        geometry: createdShape,
        symbol: { type: "simple-line", color: [21, 57, 108], width: 1 },
      });

      const labelGraphic = new Graphic({
        geometry: createdShape.centroid,
        symbol: { type: "text", text: boundary.name, color: [21, 57, 108] },
      });

      const labelLocation = {
        type: "point",
        latitude: 30.983334,
        longitude: 200.900002,
      };

      const zakLabelGraphic = new Graphic({
        geometry: labelLocation,
        symbol: { type: "text", text: boundary.name, color: [21, 57, 108] },
      });

      view.graphics.add(artccBoundaryGraphic);
      if (boundary.name === "ZAK") {
        view.graphics.add(zakLabelGraphic);
      } else {
        view.graphics.add(labelGraphic);
      }
    });
  });
};

const MapViewCard: React.FC<MapViewCardProps> = ({ showArtccBoundaries }) => {
  const { isLocalTime } = useContext(LocalTimeContext);
  const currentLocalTime = useRef(isLocalTime);
  const [, setAirportEvents] = useState<AirportEvent[]>([]);
  const [, setEnrouteEvents] = useState<EnrouteEvent[]>([]);
  const intervalRef = useRef();
  const mapRef = useRef();

  const [zoom, setZoom] = useState(parseFloat(localStorage.getItem('zoom') !== null || undefined ? localStorage.getItem('zoom') as string : "4" as string));
  const [centerLat, setCenterLat] = useState(parseFloat(localStorage.getItem('centerLat') !== null || undefined ? localStorage.getItem('centerLat') as string : "38" as string));
  const [centerLong, setCenterLong] = useState(parseFloat(localStorage.getItem('centerLong') !== null || undefined ? localStorage.getItem('centerLong') as string : "-97" as string));

  const loadMapModule = () => {
    let zoomValue = 4;
    let minZoomValue = 3;
    if (isMobile) {
      zoomValue = 2;
      minZoomValue = 2;
    }
    if (isTablet) {
      zoomValue = 3;
      minZoomValue = 3;
    }

    loadEsriModules().then(([ArcGISMap, MapView, Graphic, Polygon, Circle, Polyline, Point]) => {
      const map = new ArcGISMap({
        basemap: "gray-vector",
      });
      const view = new MapView({
        container: mapRef.current,
        map: map,
        center: [centerLong, centerLat],
        zoom: zoomValue,
        navigation: {
          extent: 1,
          browserTouchPanEnabled: true,
        },
      });


      // var newCoordinates : Number[] = [-97, 38];
      // if(centerLong && centerLat){
      //   newCoordinates[0] = Number(centerLong);
      //   newCoordinates[1] = Number(centerLat);
      //   console.log("newCoordinates", newCoordinates)
      //   view.goTo({
      //     center: newCoordinates,
      //     zoom: zoom
      //   });
      // }

      view.constraints = {
        minZoom: minZoomValue,
      };

      drawRelevantAirportNames(Graphic, view);
      drawAirportEventSymbols(Graphic, view, isLocalTime);

      restHelpers.get(API.EnrouteEvents).then((enrouteEvents: EnrouteEvent[]) => {
        enrouteEvents.forEach((enrouteEvents) => {
          drawAirspaceFlowProgram(enrouteEvents, Graphic, view, Polygon, Circle, Polyline, Point, isLocalTime);
          drawRelevantAfpName(enrouteEvents, Graphic, view);
        });
      });

      if (showArtccBoundaries) {
        drawARTCCBoundaries(Polygon, Graphic, view);
      }

      view.watch("updating", onZoomChange)
      function onZoomChange() {
      if (!view.updating){
          setZoom(view.zoom);
          setCenterLat(view.center.latitude);
          setCenterLong(view.center.longitude)
      }
    }


      return () => {
        if (view) {
          view.container = null;
        }
      };
    });
  };

  const getSubsequentAirspaceInfo = () => {
    getAndApplyLogic(`${API.AirportEvents}`, (json: AirportEvent[]) => {
      json.push({ airportId: "" } as AirportEvent);
      setAirportEvents((originalEvents) => {
        return json.map((event) => {
          const matchingEvent = originalEvents.find((originalEvent) => originalEvent.airportId === event.airportId);
          const nonMatchingEvent = originalEvents.length !== json.length;
          return checkAndUpdateNewness(event, matchingEvent, nonMatchingEvent) as AirportEvent;
        });
      });
    });
    getAndApplyLogic(`${API.EnrouteEvents}`, (json: EnrouteEvent[]) => {
      json.push({ advisoryUrl: "", airspaceFlowProgram: {} } as EnrouteEvent);
      setEnrouteEvents((originalEvents) => {
        return json.map((event) => {
          const matchingEvent = originalEvents.find(
            (originalEvent) => originalEvent.airspaceFlowProgram.afpName === event.airspaceFlowProgram.afpName
          );
          const nonMatchingEvent = originalEvents.length !== json.length;
          return checkAndUpdateNewness(event, matchingEvent, nonMatchingEvent) as EnrouteEvent;
        });
      });
    });

    if (isRefreshMapModule) {
      loadMapModule();
      isRefreshMapModule = false;
    }
    refreshCount++;
  };

  const checkAndUpdateNewness = (
    updateEvent: UpdatableEntity,
    existingEvent: UpdatableEntity | undefined,
    missingEvent: boolean
  ) => {
    const omitPaths = [
      "updateText",
      "minutesRemaining",
      "groundStop.updatedAt",
      "groundDelay.updatedAt",
      "airportClosure.updatedAt",
      "airportConfig.updatedAt",
      "airspaceFlowProgram.updatedAt",
    ];

    if (refreshCount !== 0) {
      if (existingEvent === undefined) {
        isRefreshMapModule = true;
        return updateEvent;
      }
      if (!isEqual(omit(updateEvent, omitPaths), omit(existingEvent, omitPaths))) {
        isRefreshMapModule = true;
        return updateEvent;
      }
      if (missingEvent) {
        isRefreshMapModule = true;
      }
    }

    return updateEvent;
  };

  if (currentLocalTime.current !== isLocalTime) {
    loadMapModule();
    currentLocalTime.current = isLocalTime;
  }

  useEffect(() => {
    loadMapModule();

    // @ts-ignore
    intervalRef.current = setInterval(getSubsequentAirspaceInfo, 60000);

    return () => clearInterval(intervalRef.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    localStorage.setItem('zoom', JSON.stringify(zoom));
    localStorage.setItem('centerLat', JSON.stringify(centerLat));
    localStorage.setItem('centerLong', JSON.stringify(centerLong));
    localStorage.setItem('showArtcc', JSON.stringify(showArtccBoundaries));
  }, [zoom, centerLat, centerLong, showArtccBoundaries]);// eslint-disable-line react-hooks/exhaustive-deps


  // @ts-ignore
  return <div className="web-main-map" ref={mapRef} id="web-main-map" />;
};

export default MapViewCard;
