import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AcceptancePage from "./pages/nonprod/acceptance/AcceptancePage";
import GeneralAdvisories from "./pages/nonprod/generaladvisories/GeneralAdvisories";
import FlowConstrainedAreas from "./pages/nonprod/flowconstrainedareas/FlowConstrainedAreas";
import DeadMessages from "./pages/nonprod/deadmessagequeue/DeadMessages";
import Home from "./pages/home/Home";
import Restrictions from "./pages/nonprod/restriction/Restrictions";
import { getAndUpdateState } from "./helpers/stateHelpers";
import { API } from "./helpers/restHelpers";
import { ActuatorInfo } from "./types";
import PageNotFound from "./pages/pagenotfound/PageNotFound";
import AppContext from "./AppContext";

const NasRouter: React.FC = () => {
  const [actuatorInfo, setActuatorInfo] = useState<ActuatorInfo>({ env: "PROD" });

  useEffect(() => {
    getAndUpdateState(API.ActuatorInfo, setActuatorInfo);
  }, []);

  return (
    <AppContext>
      <BrowserRouter>
        <Switch>
          <Route exact path="/list">
            <Home listViewRoute={true} mapViewRoute={false} {...actuatorInfo} />
          </Route>
          <Route exact path="/map">
            <Home listViewRoute={false} mapViewRoute={true} {...actuatorInfo} />
          </Route>
          <Route exact path="/">
            <Home listViewRoute={false} mapViewRoute={false} {...actuatorInfo} />
          </Route>
          <Route exact path="/list-zulu">
            <Home listViewRoute={true} mapViewRoute={false} zulu={true} {...actuatorInfo} />
          </Route>
          <Route exact path="/map-zulu">
            <Home listViewRoute={false} mapViewRoute={true} zulu={true} {...actuatorInfo} />
          </Route>
          <Route exact path="/zulu">
            <Home listViewRoute={false} mapViewRoute={false} zulu={true} {...actuatorInfo} />
          </Route>
          {actuatorInfo.env !== "PROD" && <Route exact path="/acceptance" component={AcceptancePage} />}
          {actuatorInfo.env !== "PROD" && <Route path="/general-advisories" component={GeneralAdvisories} />}
          {actuatorInfo.env !== "PROD" && <Route path="/flow-constrained-areas" component={FlowConstrainedAreas} />}
          {actuatorInfo.env !== "PROD" && <Route path="/dead-messages" component={DeadMessages} />}
          {actuatorInfo.env !== "PROD" && <Route path="/restrictions" component={Restrictions} />}
          <Route>
            <PageNotFound {...actuatorInfo} />
          </Route>
        </Switch>
      </BrowserRouter>
    </AppContext>
  );
};

export default NasRouter;
