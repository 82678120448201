import React from "react";
import { ReactComponent as AdvisoryLogo } from "../../../../static/Advisory-icon.svg";
import ExternalLink from "../../../ExternalLink";
import "./advisoryLink.scss";

export interface AdvisoryLinkProps {
  href?: string;
  eventType: string;
}

const AdvisoryLink: React.FC<AdvisoryLinkProps> = ({ href, eventType}) => {
  return href ? (
    <div>
      <ExternalLink href={href}
      className="card-external-link"
      metricLog={`Link View Advisory ${eventType}`}
      ariaLabel="advisory link"
      role="button"
      >
      <AdvisoryLogo className="advisory-logo" data-testid="advisory-logo" />
        Advisory
      </ExternalLink>
    </div>
  ) : (
    <></>
  );
};

export default AdvisoryLink;
