import React, { useEffect, useRef, useState } from "react";
import SectionHeader from "../../components/SectionHeader";
import AirportEventCard from "../../components/cards/airporteventcards/AirportEventCard";
import { AirspaceFlowProgramCard } from "../../components/cards/airspacefloweventcards/AirspaceFlowProgramCard";
import ForecastEventsCard from "../../components/cards/forcasteventcards/ForecastEventsCard";
import { Card } from "../../components/cards/Card";
import ExternalLink from "../../components/ExternalLink";
import AtcsccAnnouncementsCard from "../../components/cards/atcsccannouncementcards/AtcsccAnnouncementsCard";
import { AirportEvent, AtcsccAnnouncementEvent, EnrouteEvent, OperationsPlan, UpdatableEntity } from "../../types";
import { getAndApplyLogic, getAndUpdateState } from "../../helpers/stateHelpers";
import { API } from "../../helpers/restHelpers";
import { isEqual, omit } from "lodash";
import "./tileView.scss";

const TileView: React.FC = () => {
  const [airportEvents, setAirportEvents] = useState<AirportEvent[]>([]);
  const [enrouteEvents, setEnrouteEvents] = useState<EnrouteEvent[]>([]);
  const [operationsPlan, setOperationsPlan] = useState<OperationsPlan>();
  const [atcsccAnnouncements, setAtcsccAnnouncements] = useState<AtcsccAnnouncementEvent[]>([]);

  const intervalRef = useRef();

  const noPlannedEvents = () =>
    (!operationsPlan?.terminalPlanned || !operationsPlan.terminalPlanned.length) &&
    (!operationsPlan?.enRoutePlanned || !operationsPlan.enRoutePlanned.length);

  const getSubsequentAirspaceInfo = () => {
    getAndApplyLogic(`${API.AirportEvents}`, (json: AirportEvent[]) => {
      setAirportEvents((originalEvents) => {
        return json.map((event) => {
          const matchingEvent = originalEvents.find((originalEvent) => originalEvent.airportId === event.airportId);
          return checkAndUpdateNewness(event, matchingEvent) as AirportEvent;
        });
      });
    });
    getAndApplyLogic(`${API.EnrouteEvents}`, (json: EnrouteEvent[]) => {
      setEnrouteEvents((originalEvents) => {
        return json.map((event) => {
          const matchingEvent = originalEvents.find(
            (originalEvent) => originalEvent.airspaceFlowProgram.afpName === event.airspaceFlowProgram.afpName
          );
          return checkAndUpdateNewness(event, matchingEvent) as EnrouteEvent;
        });
      });
    });
    getAndUpdateState(API.OperationsPlan, setOperationsPlan);
    getAndUpdateState(`${API.AtcsccAnnouncements}`, setAtcsccAnnouncements);
  };

  useEffect(() => {
    getAndUpdateState(`${API.AirportEvents}`, setAirportEvents);
    getAndUpdateState(`${API.EnrouteEvents}`, setEnrouteEvents);
    getAndUpdateState(API.OperationsPlan, setOperationsPlan);
    getAndUpdateState(`${API.AtcsccAnnouncements}`, setAtcsccAnnouncements);

    // @ts-ignore
    intervalRef.current = setInterval(getSubsequentAirspaceInfo, 60000);

    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <>
      <section className="event-blocks">
        <SectionHeader>Active Airport Events</SectionHeader>
        <div className="card-list airport-events">
          {airportEvents.length > 0 ? (
            airportEvents.map((airportEvent) => (
              <AirportEventCard key={airportEvent.airportId} airportEvent={airportEvent} />
            ))
          ) : (
            <p className="empty-state">No active airport events.</p>
          )}
        </div>
      </section>

      <section className="event-blocks">
        <SectionHeader>Active En Route Events</SectionHeader>
        <div className="card-list enroute-events">
          {enrouteEvents.length > 0 ? (
            enrouteEvents.map((enroute) => (
              <AirspaceFlowProgramCard key={enroute.airspaceFlowProgram.afpName} enroute={enroute} />
            ))
          ) : (
            <p className="empty-state">No active en route events.</p>
          )}
        </div>
      </section>

      <section className="event-blocks">
        <SectionHeader>Forecast Events</SectionHeader>
        {noPlannedEvents() ? (
          <p className="empty-state"> No planned terminal and en route events.</p>
        ) : (
          <div className="card-list forecast-events">
            {operationsPlan?.terminalPlanned && operationsPlan.terminalPlanned.length > 0 ? (
              <ForecastEventsCard
                identifier="terminal-planned"
                title="Terminal Planned"
                events={operationsPlan.terminalPlanned}
              />
            ) : (
              <div className="empty-forecast-events">No planned terminal events.</div>
            )}
            {operationsPlan?.enRoutePlanned && operationsPlan.enRoutePlanned.length > 0 ? (
              <ForecastEventsCard
                identifier="en-route-planned"
                title="En Route Planned"
                events={operationsPlan.enRoutePlanned}
              />
            ) : (
              <div className="empty-forecast-events">No planned en route events.</div>
            )}
          </div>
        )}
        {operationsPlan?.link && (
          <Card identifier="operations-plan-link">
            <div className="operations-plan">
              <ExternalLink
                className="operations-plan-link"
                href={operationsPlan.link}
                metricLog={"Link Operations Plan"}
              >
                View Full Operations Plan
              </ExternalLink>
            </div>
          </Card>
        )}
      </section>

      <section className="event-blocks">
        <SectionHeader>Air Traffic Control System Command Center (ATCSCC) Announcements</SectionHeader>
        {atcsccAnnouncements && atcsccAnnouncements.length > 0 ? (
          <div className="card-list atcscc-events">
            <AtcsccAnnouncementsCard identifier="atcscc-announcements" events={atcsccAnnouncements} />
          </div>
        ) : (
          <p className="empty-state">No ATCSCC Announcements</p>
        )}
      </section>
    </>
  );
};

const checkAndUpdateNewness = (updateEvent: UpdatableEntity, existingEvent: UpdatableEntity | undefined) => {
  const omitPaths = [
    "updateText",
    "minutesRemaining",
    "groundStop.updatedAt",
    "groundDelay.updatedAt",
    "airportClosure.updatedAt",
    "airportConfig.updatedAt",
    "airspaceFlowProgram.updatedAt",
  ];

  if (existingEvent === undefined) {
    updateEvent.updateText = "NEW";
    updateEvent.minutesRemaining = 10;
    return updateEvent;
  }
  if (!isEqual(omit(updateEvent, omitPaths), omit(existingEvent, omitPaths))) {
    updateEvent.updateText = "UPDATED";
    updateEvent.minutesRemaining = 10;
    return updateEvent;
  }

  updateEvent.minutesRemaining = existingEvent.minutesRemaining! - 1;
  updateEvent.updateText = updateEvent.minutesRemaining > 0 ? existingEvent.updateText : undefined;

  return updateEvent;
};

export default TileView;
