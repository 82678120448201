import React, { useEffect, useRef } from "react";
import { loadModules } from "esri-loader";
import "./airspaceFlowProgramMap.scss";
import restHelpers, { API } from "../../../helpers/restHelpers";
import { ArtccBoundary } from "../../../types";

export interface AirspaceFlowProgramMapProps {}

const loadEsriModules = () =>
  loadModules(
    [
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/geometry/Polygon",
      "esri/geometry/Circle",
      "esri/geometry/Polyline",
    ],
    {
      css: true,
    }
  );

const drawARTCCBoundaries = (Polygon: any, Graphic: any, view: any) => {
  restHelpers.get(API.ArtccBoundaries).then((boundaries: ArtccBoundary[]) => {
    boundaries.forEach((boundary) => {
      const rings = boundary.points.map((point: any) => {
        return [parseFloat(point.longitude), parseFloat(point.latitude)];
      });
      rings.push([parseFloat(boundary.points[0].longitude), parseFloat(boundary.points[0].latitude)]);
      const createdShape = new Polygon({
        rings: rings,
      });

      const artccBoundaryGraphic = new Graphic({
        geometry: createdShape,
        symbol: { type: "simple-line", color: [175, 238, 238], width: 1 },
      });

      const labelGraphic = new Graphic({
        geometry: createdShape.centroid,
        symbol: { type: "text", text: boundary.name, color: [175, 238, 238] },
      });

      view.graphics.add(artccBoundaryGraphic);
      view.graphics.add(labelGraphic);
    });
  });
};

const AirspaceFlowProgramEmptyMap: React.FC<AirspaceFlowProgramMapProps> = (props: AirspaceFlowProgramMapProps) => {
  const mapRef = useRef();

  useEffect(() => {
    loadEsriModules().then(([ArcGISMap, MapView, Graphic, Polygon, Circle, Polyline]) => {
      const map = new ArcGISMap({
        basemap: "dark-gray-vector",
      });

      const view = new MapView({
        container: mapRef.current,
        map: map,
        center: [-97, 38],
        zoom: 3.5,
        navigation: {
          browserTouchPanEnabled: true,
        },
      });

      drawARTCCBoundaries(Polygon, Graphic, view);

      return () => {
        if (view) {
          view.container = null;
        }
      };
    });
  }, []);

  // @ts-ignore
  return <div className="webmap" ref={mapRef} />;
};

export default AirspaceFlowProgramEmptyMap;
