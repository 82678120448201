import React, { useContext } from "react";
import { ArrivalDepartureDelay } from "../../../../types";
import "../airportEventCard.scss";
import "../../shared/timerange/timeRange.scss";
import { toggleZuluAndLocalTime } from "../../../../helpers/dateHelper";
import { LocalTimeContext } from "../../../../AppContext";

export interface ArrivalDepartureDelayBlockProps {
  arrivalDepartureDelay: ArrivalDepartureDelay;
  showDetails: boolean;
  airportLongName?: string;
}

const ArrivalDepartureDelayBlock: React.FC<ArrivalDepartureDelayBlockProps> = ({
  arrivalDepartureDelay,
  showDetails,
  airportLongName,
}) => {
  const { isLocalTime } = useContext(LocalTimeContext);
  return (
    <div
      className="airport-card-block"
      data-testid={`${arrivalDepartureDelay.airportId}-${arrivalDepartureDelay.arrivalDeparture.type}-delay-block`}
    >
      <div className="airport-card-content">
        <p className="event-type">{arrivalDepartureDelay.arrivalDeparture.type} Delay</p>
        <p className="event-time-block">
          <span>Updated </span>
          <span className="event-time">{toggleZuluAndLocalTime(arrivalDepartureDelay.updateTime, isLocalTime)}</span>
        </p>
      </div>
      <div className="airport-card-content">
        {!showDetails && (
          <p>
            Avg. {arrivalDepartureDelay.averageDelay} mins. (and {arrivalDepartureDelay.trend}) due to{" "}
            {arrivalDepartureDelay.reason.toLowerCase()}.
          </p>
        )}
        {showDetails && arrivalDepartureDelay.arrivalDeparture.type === "Arrival" && (
          <p>
            {arrivalDepartureDelay.arrivalDeparture.type}s to{" "}
            {airportLongName ? airportLongName : arrivalDepartureDelay.airportId} are delayed an avg.{" "}
            {arrivalDepartureDelay.averageDelay} mins. (and {arrivalDepartureDelay.trend}) due to{" "}
            {arrivalDepartureDelay.reason.toLowerCase()}.
          </p>
        )}
        {showDetails && arrivalDepartureDelay.arrivalDeparture.type === "Departure" && (
          <p>
            {arrivalDepartureDelay.arrivalDeparture.type}s from{" "}
            {airportLongName ? airportLongName : arrivalDepartureDelay.airportId} are delayed an avg.{" "}
            {arrivalDepartureDelay.averageDelay} mins. (and {arrivalDepartureDelay.trend}) due to{" "}
            {arrivalDepartureDelay.reason.toLowerCase()}.
          </p>
        )}
      </div>
    </div>
  );
};
export default ArrivalDepartureDelayBlock;
