import React from "react";
import { formatDateToString } from "../helpers/dateHelper";
import Moment from "moment";

export interface XmlTableProps {
  tableTitle: string;
  tableValues: { id: string; xml: string; createdAt?: Date }[];
  className?: string;
  displayXmlWithFormatting?: boolean;
}

const REFRESH_TEXT = 'refresh="RFRS"';

const XmlTable: React.FC<XmlTableProps> = ({
  tableTitle,
  tableValues,
  className,
  displayXmlWithFormatting,
}: XmlTableProps) => {
  return (
    <div className={className}>
      <h2>{tableTitle}</h2>
      <table aria-label={tableTitle}>
        <tbody>
          <tr>
            <th>ID</th>
            <th>CREATED AT</th>
            <th>XML</th>
          </tr>
          {tableValues
            .sort((a, b) => Moment(b.createdAt).diff(a.createdAt))
            .filter((value) => !value.xml.includes(REFRESH_TEXT))
            .map((value, index) => {
              return (
                <tr key={value.id} aria-label={`${tableTitle} - ${index}`}>
                  <td key={`id-${value.id}`}>{value.id}</td>
                  <td key={`createdAt-${value.id}`} className="created-at">
                    {formatDateToString(value.createdAt)}
                  </td>
                  <td key={`xml-${value.id}`}>
                    {displayXmlWithFormatting ? <pre>{value.xml}</pre> : <>{value.xml}</>}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default XmlTable;
