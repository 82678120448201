import React, { useEffect } from "react";
import XmlTable from "../../../components/XmlTable";
import { XmlApiResponse } from "../../../types";
import { getAndUpdateState } from "../../../helpers/stateHelpers";
import { API } from "../../../helpers/restHelpers";

const DeadMessages: React.FC = () => {
  const [deadMessages, setDeadMessages] = React.useState<XmlApiResponse[]>([]);

  useEffect(() => {
    getAndUpdateState(API.DeadMessages, setDeadMessages);
  }, []);

  return (
    <div>
      <XmlTable tableTitle="Dead Messages" tableValues={deadMessages} />
    </div>
  );
};

export default DeadMessages;
