import React from "react";
import { XmlApiResponse } from "../../../types";
import XmlTable, { XmlTableProps } from "../../../components/XmlTable";

export interface AcceptanceProps {
  apiIsUp: string;
  database: string;
  jmsHealthy: boolean;
  groundStops: XmlApiResponse[];
  airspaceFlowPrograms: XmlApiResponse[];
  groundDelayPrograms: XmlApiResponse[];
  ctops: XmlApiResponse[];
  generalAdvisories: XmlApiResponse[];
  flowConstrainedAreas: XmlApiResponse[];
  restrictions: XmlApiResponse[];
  reroutes: XmlApiResponse[];
  deicing: XmlApiResponse[];
  airportClosures: XmlApiResponse[];
  fadts: XmlApiResponse[];
  params: XmlApiResponse[];
  airportConfigs: XmlApiResponse[];
  freeForm: XmlApiResponse[];
}

const Acceptance: React.FC<AcceptanceProps> = ({
  apiIsUp,
  database,
  jmsHealthy,
  groundStops,
  airspaceFlowPrograms,
  groundDelayPrograms,
  ctops,
  generalAdvisories,
  flowConstrainedAreas,
  restrictions,
  reroutes,
  deicing,
  airportClosures,
  fadts,
  params,
  airportConfigs,
  freeForm,
}: AcceptanceProps) => {
  const xmlTableProps: XmlTableProps[] = [
    {
      className: "airport-configurations-table",
      tableTitle: "Airport Configs Table",
      tableValues: airportConfigs,
    },
    {
      className: "params-table",
      tableTitle: "Params Table",
      tableValues: params,
    },
    {
      className: "ground-stops-table",
      tableTitle: "Ground Stops Table",
      tableValues: groundStops,
    },
    {
      className: "airspace-flow-programs-table",
      tableTitle: "Airspace Flow Programs Table",
      tableValues: airspaceFlowPrograms,
    },
    {
      className: "ground-delay-programs-table",
      tableTitle: "Ground Delay Programs Table",
      tableValues: groundDelayPrograms,
    },
    { className: "ctops-table", tableTitle: "CTOPs Table", tableValues: ctops },
    {
      className: "general-advisories-table",
      tableTitle: "General Advisories Table",
      tableValues: generalAdvisories,
    },
    {
      className: "flow-constrained-areas-table",
      tableTitle: "Flow Constrained Areas Table",
      tableValues: flowConstrainedAreas,
    },
    {
      className: "restrictions-table",
      tableTitle: "Restrictions Table",
      tableValues: restrictions,
    },
    {
      className: "reroutes-table",
      tableTitle: "Reroutes Table",
      tableValues: reroutes,
    },
    {
      className: "deicing-table",
      tableTitle: "Deicing Table",
      tableValues: deicing,
    },
    {
      className: "airport-closures-table",
      tableTitle: "Airport Closures Table",
      tableValues: airportClosures,
    },
    { className: "fadts-table", tableTitle: "FADTs Table", tableValues: fadts },
    {
      className: "free-form-table",
      tableTitle: "Free Form Table",
      tableValues: freeForm,
    },
  ];

  return (
    <div>
      <h2>App Status</h2>
      <div>Api Connection: {apiIsUp}</div>
      <div>Database: {database}</div>
      <ul className="available-tables" aria-label="Available Tables">
        <h2>Available Tables</h2>
        {xmlTableProps.map((tableProps, index) => (
          <li key={index}>{tableProps.tableTitle}</li>
        ))}
      </ul>
      {!jmsHealthy && <div>Unable to establish SWIM Connection</div>}
      <div aria-label="SWIM Messages">
        <h2>SWIM Messages</h2>
        {xmlTableProps.map((tableProps, index) => (
          <XmlTable key={index} {...tableProps} />
        ))}
      </div>
    </div>
  );
};

export default Acceptance;
