import React, { useContext } from "react";
import { OperationsPlanEvent } from "../../../types";
import "./forecastEventsCard.scss";
import { Card } from "../Card";
import moment from "moment";
import { LocalTimeContext } from "../../../AppContext";
import { toggleZuluAndLocalTime } from "../../../helpers/dateHelper";

export interface ForecastEventsCardProps {
  identifier: string;
  title: string;
  events: OperationsPlanEvent[];
}

const toggleForecastTimeToLocalTime = (timeText: string, toLocal: boolean) => {
  const timeTextSubString = timeText.split(" ");
  let result = "";
  if (timeTextSubString[1] != null && timeTextSubString[1] !== "") {
    const timePrefix = timeTextSubString[0];
    const timeSubString = timeTextSubString[1].split("/");
    result =
      timePrefix +
      (timePrefix ? " " : "") +
      (timeSubString[1]
        ? (timeSubString[0].length > 2
            ? toggleZuluAndLocalTime(convertHoursStringToZulu(timeSubString[0]), toLocal)
            : timeSubString[0]) +
          (timeSubString[1] ? "/" : "") +
          toggleZuluAndLocalTime(convertHoursStringToZulu(timeSubString[1]), toLocal)
        : toggleZuluAndLocalTime(convertHoursStringToZulu(timeSubString[0]), toLocal));
  } else {
    const timeSubString = timeTextSubString[0].split("/");
    result = timeSubString[1]
      ? toggleZuluAndLocalTime(convertHoursStringToZulu(timeSubString[0]), toLocal) +
        (timeSubString[1] ? "/" : "") +
        toggleZuluAndLocalTime(convertHoursStringToZulu(timeSubString[1]), toLocal)
      : toggleZuluAndLocalTime(convertHoursStringToZulu(timeSubString[0]), toLocal);
  }
  return result;
};

const convertHoursStringToZulu = (timeString: string) => {
  if (timeString != null && timeString !== "") {
    const hours = timeString.substring(0, 2);
    let min = timeString.substring(2);
    if (min === "") {
      min = "00";
    }
    if (min.length === 1) {
      min = min + "0";
    }
    const utcTimeString = moment().format("YYYY-MM-DD") + `T${hours}:${min}:00Z`;
    return moment(utcTimeString).utc().toDate();
  }
  return undefined;
};

const ForecastEventsCard: React.FC<ForecastEventsCardProps> = (props) => {
  const { isLocalTime } = useContext(LocalTimeContext);
  return (
    <Card additionalClassNames="forecast-card" identifier={props.identifier}>
      <table className="forecast-table">
        <caption className="forecast-table-caption">{props.title}</caption>
        <thead>
          <tr data-testid={`${props.identifier}-table-header`}>
            <th className="forecast-table-element forecast-table-header">
              <p>Time</p>
            </th>
            <td className="empty-cell" />
            <th className="forecast-table-element forecast-table-header">
              <p>Event</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.events.map((event, index) => {
            return (
              <tr
                className="forecast-table-row"
                data-testid={`${props.identifier}-table-row-${index}`}
                key={`${props.identifier}-row-${index}`}
              >
                <td className="forecast-table-element forecast-time">
                  {toggleForecastTimeToLocalTime(event.time, isLocalTime)}
                </td>
                <td className="empty-cell" />
                <td className="forecast-table-element forecast-event">
                  <p className="forecast-event-text">{event.event}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export default ForecastEventsCard;
