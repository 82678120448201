import React, { useRef } from "react";
import "../../airporteventcards/airportEventCard.scss";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ScopeMapModal.scss";
import { loadModules } from "esri-loader";
import { ArtccBoundary } from "../../../../types";
import restHelpers, { API } from "../../../../helpers/restHelpers";
import { ReactComponent as ScopeLogo } from "../../../../static/Scope-icon.svg";
export interface ScopeMapModalProps {
  identifier: string;
  airportId: string;
  eventType: string;
  facilities?: string[] | null;
  latitude?: string;
  longitude?: string;
  nauticalMiles?: number | null;
}

const isMobile = window.matchMedia && window.matchMedia("(max-width: 649px)").matches;

const loadEsriModules = () =>
  loadModules(
    [
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/geometry/Polygon",
      "esri/geometry/Circle"
    ],
    { css: true }
  );

  const GROUND_STOP = "Ground Stop";
  const GROUND_DELAY = "Ground Delay";

const ScopeMapModal: React.FC<ScopeMapModalProps> = ({ identifier, airportId, eventType, facilities, latitude, longitude, nauticalMiles}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const mapRef = useRef();

  function openModal() {
    setIsOpen(true);
    // postMetricLog("Scope Map Button");
  }

  function closeModal() {
    setIsOpen(false);
  }

  const drawRelevantAirportNames = (Graphic: any, view: any,) => {

    if (latitude !== null || longitude !== null) {
      const labelLocation = {
        type: "point",
        latitude: parseFloat(latitude as string),
        longitude: parseFloat(longitude as string),
      };

      const relevantAirportIdlabelGraphic = new Graphic({
        geometry: labelLocation,
        symbol: { type: "text", text: airportId, color: [201, 16, 16], font: { size: 9 } },
      });

      view.graphics.add(relevantAirportIdlabelGraphic);
    }
  };

  const drawARTCCBoundaries = (Polygon: any, Graphic: any, view: any) => {
    restHelpers.get(API.ArtccBoundaries).then((boundaries: ArtccBoundary[]) => {
      boundaries.forEach((boundary) => {
        const rings = boundary.points.map((point: any) => {
          return [parseFloat(point.longitude), parseFloat(point.latitude)];
        });
        rings.push([parseFloat(boundary.points[0].longitude), parseFloat(boundary.points[0].latitude)]);
        const createdShape = new Polygon({
          rings: rings,
        });

        const artccBoundaryGraphic = new Graphic({
          geometry: createdShape,
          symbol: { type: "simple-line", color: [21, 57, 108], width: 1 },
        });

        const labelGraphic = new Graphic({
          geometry: createdShape.centroid,
          symbol: { type: "text", text: boundary.name, color: [21, 57, 108] },
        });

        facilities?.forEach((facility) => {

          if (facility === boundary.name) {

            const scopeGraphic = new Graphic({
              geometry: createdShape,
              symbol: { type: "simple-fill", color: "rgba(243,111,111,0.2)" },
            });
            view.graphics.add(scopeGraphic);
          }
        })

        view.graphics.add(artccBoundaryGraphic);
        view.graphics.add(labelGraphic);

      });
    });
  };

  const drawNauticalMilesScope = (Circle: any, Graphic: any, view: any) => {

    const createdShape = new Circle({
      center: {
        latitude: latitude,
        longitude: longitude,
      },
      radius: nauticalMiles,
      radiusUnit: "nautical-miles"
    });

    const graphic = new Graphic({
      geometry: createdShape,
      symbol: { type: "simple-fill", color: "rgba(255,148,124,0.4)" }
    });

    view.graphics.add(graphic);
  }
 
  let zoomLevel = 3
    if(isMobile){
      zoomLevel = 2;
    }else{
      zoomLevel = 3;
    }

  const loadMapModule = () => {
    loadEsriModules().then(([ArcGISMap, MapView, Graphic, Polygon, Circle]) => {
      const map = new ArcGISMap({
        basemap: "gray-vector",
      });

      const view = new MapView({
        container: mapRef.current,
        map: map,
        center: [-97, 38],
        zoom: zoomLevel,
        navigation: {
          browserTouchPanEnabled: false,
        },
      });

      drawARTCCBoundaries(Polygon, Graphic, view);
      drawRelevantAirportNames(Graphic, view);

      if (eventType === GROUND_DELAY) {
        drawNauticalMilesScope(Circle, Graphic, view);
      }

      return () => {
        if (view) {
          view.container = null;
        }
      };
    });
  }

  function openScopeMap() {
    loadMapModule();
    //@ts-ignore
    return (<div className="webmap-scope" ref={mapRef} id="webmap-scope"></div>);
  }

  return (
    <div>
      <button
        className="scope-button"
        data-testid={`${identifier}-scope-map-button`}
        onClick={openModal}
      >
        <ScopeLogo className="scope-logo" data-testid="scope-map-logo" />
        Scope
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="scope-modal"
        overlayClassName="scope-modal-overlay"
        contentLabel="Scope Map Modal"
        ariaHideApp={false}
      >
        <button
          className="scope-map-close"
          data-testid={"scope-map-close-button"}
          aria-label={"Close Scope Map"}
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {isMobile && <h2 className="scope-facilities">{airportId} {eventType} Scope</h2>}
        
        {!isMobile && eventType === GROUND_STOP && <h2 className="scope-facilities">{airportId} {eventType} Scope: {facilities?.join(", ")}</h2>}
        {!isMobile && eventType === GROUND_DELAY && facilities !== null && nauticalMiles === null && <h2 className="scope-facilities">{airportId} {eventType} Scope: {facilities?.join(", ")}</h2>}
        {!isMobile && eventType === GROUND_DELAY && nauticalMiles !== null && facilities === null && <h2 className="scope-facilities">{airportId} {eventType} Scope: {nauticalMiles} nautical miles</h2>}
        {!isMobile && eventType === GROUND_DELAY && nauticalMiles !== null && facilities !== null && <h2 className="scope-facilities">{airportId} {eventType} Scope: {nauticalMiles} nautical miles + {facilities?.join(", ")}</h2>}
        {openScopeMap()}
        <div>
        </div>
      </Modal>
    </div>
  );

};
Modal.setAppElement("body");

export default ScopeMapModal;

