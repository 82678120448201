import React, { useEffect } from "react";
import XmlTable from "../../../components/XmlTable";
import { XmlApiResponse } from "../../../types";
import { getAndUpdateState } from "../../../helpers/stateHelpers";
import { API } from "../../../helpers/restHelpers";

const FlowConstrainedAreas: React.FC = () => {
  const [flowConstrainedAreaXmls, setFlowConstrainedAreaXmls] = React.useState<XmlApiResponse[]>([]);

  useEffect(() => {
    getAndUpdateState(API.FlowConstrainedAreas, setFlowConstrainedAreaXmls);
  }, []);

  return (
    <div>
      <XmlTable tableTitle="Active Flow Constrained Areas" tableValues={flowConstrainedAreaXmls} />
    </div>
  );
};

export default FlowConstrainedAreas;
