import { TimeRange } from "../shared/timerange/TimeRange";
import React from "react";
import { Card } from "../Card";
import "./airspaceFlowProgramCard.scss";
import AirspaceFlowProgramMap from "./AirspaceFlowProgramMap";
import { EnrouteEvent } from "../../../types";
import Badge from "../shared/badge/Badge";
import AdvisoryLink from "../shared/advisorylink/AdvisoryLink";
import ExternalLink from "../../ExternalLink";
import DelayOverTimeModal from "../shared/delayovertimemodal/DelayOverTimeModal";
import AirspaceFlowProgramEmptyMap from "./AirspaceFlowProgramEmptyMap";

interface AirspaceFlowProgramCardProps {
  enroute: EnrouteEvent;
}

export const AirspaceFlowProgramCard: React.FC<AirspaceFlowProgramCardProps> = ({ enroute }) => (
  <Card additionalClassNames={"afp-card"} identifier={`${enroute.airspaceFlowProgram.afpName}`}>
    <div>
      <div className="afp-header">
        <div className="afp-header-column">
          <div>
            {enroute.airspaceFlowProgram.afpName}
            {enroute.updateText && (
              <Badge className="badge" identifier={enroute.airspaceFlowProgram.afpName} text={enroute.updateText} />
            )}
          </div>
          <span className="afp-name-large">Airspace Flow Program</span>
          <span className="afp-name-small">AFP</span>
        </div>
        <div className="afp-header-column">
          <div className="empty-afp-div" />
          <div>
            <TimeRange
              startTime={enroute.airspaceFlowProgram.startTime}
              endTime={enroute.airspaceFlowProgram.endTime}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="afp-card-info-container">
      {(enroute.polygon !== null ||
        enroute.circle !== null ||
        enroute.line !== null ||
        enroute.fcaAirport !== null ||
        enroute.fcaArtcc !== null) && (
        <div
          className="map-block"
          data-testid={`card-${enroute.airspaceFlowProgram.afpName}-map`}
        >
          <AirspaceFlowProgramMap
            shape={enroute.polygon || enroute.circle || enroute.line || enroute.fcaAirport || enroute.fcaArtcc}
            typeOfShape={
              (enroute.polygon && "Polygon") ||
              (enroute.circle && "Circle") ||
              (enroute.line && "Line") ||
              (enroute.fcaAirport && "FcaAirport") ||
              (enroute.fcaArtcc && "FcaArtcc")
            }
          />
        </div>
      )}
      {enroute.polygon === null &&
        enroute.circle === null &&
        enroute.line === null &&
        enroute.fcaAirport === null &&
        enroute.fcaArtcc === null && (
          <div
            className="map-block"
            role="region"
            aria-label={`map for ${enroute.airspaceFlowProgram.afpName} afp`}
            data-testid={`empty-map-container`}
          >
            <AirspaceFlowProgramEmptyMap />
          </div>
        )}
      <div className="afp-info-block">
        <div className="afp-card-block">
          <p>
            {(function () {
              if (enroute.fcaAirport) {
                return `Flights operating at ${enroute.fcaAirport?.fcaAirportName} will be delayed an avg.`;
              } else if (enroute.fcaArtcc) {
                return `Flights entering ${enroute.fcaArtcc?.fcaArtccName} ARTCC will be delayed an avg.`;
              } else if (enroute.fcaSectorName) {
                return `Flights entering ${enroute.fcaSectorName} sector will be delayed an avg.`;
              } else if (enroute.fcaBaseSector) {
                return `Flights entering ${enroute.fcaBaseSector} sector will be delayed an avg.`;
              } else if (enroute.fcaTraconName) {
                return `Flights entering ${enroute.fcaTraconName} TRACON will be delayed an avg.`;
              } else if (enroute.fcaBaseTracon) {
                return `Flights entering ${enroute.fcaBaseTracon} TRACON will be delayed an avg.`;
              } else if (enroute.fcaSuaName) {
                return `Flights entering ${enroute.fcaSuaName} Special Use Airspace will be delayed an avg.`;
              } else if (enroute.fcaFixName) {
                return `Flights crossing the ${enroute.fcaFixName} fix will be delayed an avg.`;
              } else {
                return `Flights crossing the Flow Constrained Area (FCA) will be delayed an avg.`;
              }
            })()}{" "}
            {`${Math.round(enroute.airspaceFlowProgram.avgDelay)} mins. or routed around due to ${
              enroute.airspaceFlowProgram.impactingCondition
            }. `}
            {!enroute.departsAny &&
              !enroute.arrivesAny &&
              enroute.isAnyConditions === false &&
              !enroute.fcaHeadingDirection && (
                <span>
                  FCA applies to departures.<span> </span>
                </span>
              )}
            {!enroute.departsAny &&
              !enroute.arrivesAny &&
              enroute.isAnyConditions === false &&
              enroute.fcaHeadingDirection && (
                <span>
                  FCA applies to {enroute.fcaHeadingDirection} departures.<span> </span>
                </span>
              )}
            {!enroute.departsAny &&
              enroute.arrivesAny &&
              enroute.isAnyConditions === false &&
              !enroute.fcaHeadingDirection && (
                <span>
                  FCA applies to departures destined to {enroute.arrivesAny}.<span> </span>
                </span>
              )}
            {!enroute.departsAny &&
              enroute.arrivesAny &&
              enroute.isAnyConditions === false &&
              enroute.fcaHeadingDirection && (
                <span>
                  FCA applies to {enroute.fcaHeadingDirection} departures destined to {enroute.arrivesAny}.
                  <span> </span>
                </span>
              )}
            {enroute.departsAny &&
              !enroute.arrivesAny &&
              enroute.isAnyConditions === false &&
              !enroute.fcaHeadingDirection && (
                <span>
                  FCA applies to departures originating at {enroute.departsAny}.<span> </span>
                </span>
              )}
            {enroute.departsAny &&
              !enroute.arrivesAny &&
              enroute.isAnyConditions === false &&
              enroute.fcaHeadingDirection && (
                <span>
                  FCA applies to {enroute.fcaHeadingDirection} departures originating at {enroute.departsAny}.
                  <span> </span>
                </span>
              )}
            {enroute.departsAny &&
              enroute.arrivesAny &&
              enroute.isAnyConditions === false &&
              !enroute.fcaHeadingDirection && (
                <span>
                  FCA applies to departures originating at {enroute.departsAny} and destined to {enroute.arrivesAny}.
                  <span> </span>
                </span>
              )}
            {enroute.departsAny &&
              enroute.arrivesAny &&
              enroute.isAnyConditions === false &&
              enroute.fcaHeadingDirection && (
                <span>
                  FCA applies to {enroute.fcaHeadingDirection} departures originating at {enroute.departsAny} and
                  destined to {enroute.arrivesAny}.<span> </span>
                </span>
              )}
            {enroute.departsNone && enroute.isAnyConditions === false && (
              <span>
                Flights originating at {enroute.departsNone} are excluded.<span> </span>
              </span>
            )}
            {enroute.arrivesNone && enroute.isAnyConditions === false && (
              <span>
                Flights destined to {enroute.arrivesNone} are excluded.<span> </span>
              </span>
            )}
            {!enroute.departsAny && !enroute.arrivesAny && enroute.isAnyConditions && (
              <span>
                FCA applies to departures.<span> </span>
              </span>
            )}
            {!enroute.departsAny && enroute.arrivesAny && enroute.isAnyConditions && (
              <span>
                FCA applies to departures destined to {enroute.arrivesAny}.<span> </span>
              </span>
            )}
            {enroute.departsAny && !enroute.arrivesAny && enroute.isAnyConditions && (
              <span>
                FCA applies to departures originating at {enroute.departsAny}.<span> </span>
              </span>
            )}
            {enroute.departsAny && enroute.arrivesAny && enroute.isAnyConditions && (
              <span>
                FCA applies to departures originating at {enroute.departsAny} or destined to {enroute.arrivesAny}.
                <span> </span>
              </span>
            )}
            {enroute.departsNone && enroute.isAnyConditions && (
              <span>
                Flights originating at {enroute.departsNone} are excluded.<span> </span>
              </span>
            )}
            {enroute.arrivesNone && enroute.isAnyConditions && (
              <span>
                Flights destined to {enroute.arrivesNone} are excluded.<span> </span>
              </span>
            )}
          </p>
        </div>
        <div className="afp-card-block">
          <p className="afp-altitude-section">
            <strong>Altitude Floor/Ceiling: </strong>
            {`${enroute.airspaceFlowProgram.lowerAltitude}/${enroute.airspaceFlowProgram.upperAltitude}`}
          </p>
        </div>
        <div className="afp-links-container">
          {enroute.advisoryUrl && (
            <div className="afp-external-link">
              <AdvisoryLink href={enroute.advisoryUrl} eventType={"AirspaceFlowProgram"}/>
            </div>
          )}

          {enroute.airspaceFlowProgram.fuelFlowAdvisoryDelayTime && (
            <div className="afp-external-link">
              <DelayOverTimeModal
                fuelFlowAdvisoryDelayTime={enroute.airspaceFlowProgram.fuelFlowAdvisoryDelayTime}
                delayTableDescription={"Delay by each hour when crossing AFP boundary."}
                delayTableCaption={`AFP ${enroute.airspaceFlowProgram.fuelFlowAdvisoryDelayTime.controlElement}`}
              />
            </div>
          )}

          <div className="afp-external-link">
            <ExternalLink
              className="card-external-link"
              href="https://www.fly.faa.gov/edct/jsp/edctLookUp.jsp"
              metricLog={"Link EDCT AirspaceFlowProgram"}
            >
              EDCT Lookup
            </ExternalLink>
          </div>
        </div>
        <div className="afp-card-block">
          <p>
            <strong>Comments: </strong>
            {enroute.comments}
          </p>
        </div>
      </div>
    </div>
  </Card>
);
