import React from "react";
import { AirportConfig } from "../../../../types";
import "./runwayConfigBlock.scss";

const RunwayConfigBlock: React.FC<AirportConfig> = ({
  arrivalRunwayConfig,
  departureRunwayConfig,
  arrivalRate,
  airportId,
}) => (
  <div className="airport-card-block" data-testid={`${airportId}-runway-config-block`}>
    <div className="airport-card-content">
      <p className="runway-content-header">Current Airport Config.</p>
    </div>
    <div className="runway-card">
      <div className="runway-content">
        <p className="runway-text" data-testid="runway-arrival-text">
          Arrival
        </p>
        <p className="runway-text">Runway</p>
        <p className="runway-content-values">{arrivalRunwayConfig}</p>
      </div>
      <div className="runway-content">
        <p className="runway-text">Departure</p>
        <p className="runway-text">Runway</p>
        <p className="runway-content-values">{departureRunwayConfig}</p>
      </div>
      {arrivalRate !== 0 && arrivalRate !== null && (
        <div className="runway-content">
          <p className="runway-text">Arrival</p>
          <p className="runway-text">Rate</p>
          <p className="runway-content-values">{arrivalRate}</p>
        </div>
      )}
    </div>
  </div>
);
export default RunwayConfigBlock;
