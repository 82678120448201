import React, { useContext, useRef, useState } from "react";
import { ReactComponent as Logo } from "../../static/FAA-logo.svg";
import { ReactComponent as Menu } from "../../static/menu.svg";
import "./header.scss";
import { HeaderLinks } from "./HeaderLinks";
import { toggleZuluAndLocalTime } from "../../helpers/dateHelper";
import BetaBanner from "./BetaBanner";
import FlyoutMenu from "./FlyoutMenu";
import { DotLinks } from "./DotLinks";
import ExternalLink from "../ExternalLink";
import TickingClock from "./TickingClock";
import { LocalTimeContext } from "../../AppContext";

interface HeaderProps {
  lastUpdatedTime?: Date;
}

export const Header: React.FC<HeaderProps> = ({ lastUpdatedTime }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const { isLocalTime } = useContext(LocalTimeContext);

  return (
    <header ref={headerRef}>
      <DotLinks />
      <div className="app-header" data-testid="app-header">
        <div>
          <ExternalLink
            href={"/"}
            metricLog={"Link FAA Logo"}
            testId={"logo-link"}
            ariaLabel={"Federal Aviation Administration Logo Clickable Link"}
            openInNewTab={false}
            className={"faa-home-link"}
          >
            <div className="logo-container">
              <Logo data-testid="logo" />
              <span className="faa-name">Federal Aviation Administration</span>
            </div>
          </ExternalLink>
        </div>

        <div>
          <h1 className="app-title" data-testid="app-title">
            National Airspace System Status
          </h1>
          <div className="horizontal-time-block" data-testid="horizontal-time-block">
            <div className="incrementing-time" data-testid="last-updated-time">
              Updated: {toggleZuluAndLocalTime(lastUpdatedTime, isLocalTime, true)}
            </div>
            <TickingClock />
          </div>
        </div>
        <FlyoutMenu
          showMenu={showMenu}
          handleClose={() => {
            setShowMenu(false);
            document.body.style.overflow = "scroll";
          }}
        />
        <div>
          <div className="menu-container" data-testid="menu-container">
            <Menu
              data-testid="menu-icon"
              onClick={() => {
                setShowMenu(true);
                document.body.style.overflow = "hidden";
              }}
            />
          </div>
          <div className="vertical-time-block" data-testid="vertical-time-block">
            <div className="incrementing-time" data-testid="last-updated-time">
              Updated: {toggleZuluAndLocalTime(lastUpdatedTime, isLocalTime, true)}
            </div>
            <TickingClock />
          </div>
        </div>
      </div>
      <nav className="navigation-bar" data-testid="navigation-bar">
        <HeaderLinks />
      </nav>
      <BetaBanner />
    </header>
  );
};
