import React, { useEffect } from "react";
import { XmlApiResponse } from "../../../types";
import { getAndUpdateState } from "../../../helpers/stateHelpers";
import { API } from "../../../helpers/restHelpers";
import XmlTable from "../../../components/XmlTable";

const Restrictions: React.FC = () => {
  const [activeRestrictions, setActiveRestrictions] = React.useState<XmlApiResponse[]>([]);

  useEffect(() => {
    getAndUpdateState(API.Restrictions, setActiveRestrictions);
  }, []);

  return (
    <div>
      <XmlTable tableTitle="Active Restrictions" tableValues={activeRestrictions} />
    </div>
  );
};

export default Restrictions;
