import React from "react";
import "./footer.scss";
import { ReactComponent as DotLogo } from "../../static/DOT-logo-blue.svg";
import ExternalLink from "../ExternalLink";

// @ts-ignore
import UserGuide from "../../static/NASStatusUserGuide.pdf";

interface FooterProps {
  version?: string;
}

const Footer: React.FC<FooterProps> = ({ version }) => {
  return (
    <footer data-testid="app-footer" aria-label={"Footer Links"}>
      <section className="faa-footer">
        <div className="faa-footer-column-container">
          <div className="footer-column">
            <h3 className="footer-column-header">Planning</h3>
            <ExternalLink
              href={"https://www.fly.faa.gov/PLAYBOOK/pbindex.html"}
              className={"faa-link"}
              metricLog={"Link Footer HTML Playbook"}
            >
              Playbook HTML Version
            </ExternalLink>
            <ExternalLink
              href={"https://www.fly.faa.gov/Operations/playbook/current/current.pdf"}
              className={"faa-link"}
              metricLog={"Link Footer PDF Playbook"}
            >
              Playbook PDF Version
            </ExternalLink>
            <ExternalLink
              href={"https://www.fly.faa.gov/rmt/coded_departure_routes.jsp"}
              className={"faa-link"}
              metricLog={"Link Footer Route Management Tool"}
            >
              Route Management Tool
            </ExternalLink>
          </div>
          <div className="footer-column">
            <h3 className="footer-column-header">Reservation</h3>
            <ExternalLink
              href={"https://www.fly.faa.gov/estmp/index.html"}
              className={"faa-link"}
              metricLog={"Link Footer eSTMP"}
            >
              eSTMP
            </ExternalLink>
            <ExternalLink
              href={"https://www.fly.faa.gov/ecvrs/index.html"}
              className={"faa-link"}
              metricLog={"Link Footer eCVRS"}
            >
              eCVRS
            </ExternalLink>
          </div>
          <div className="footer-column">
            <h3 className="footer-column-header">NAS Support</h3>
            <ExternalLink
              href={"https://www.fly.faa.gov/ais/jsp/ais.jsp"}
              className={"faa-link"}
              metricLog={"Link Footer Aviation Information System"}
            >
              Aviation Information System
            </ExternalLink>
            <ExternalLink
              href={"https://www.fly.faa.gov/FAQ/Acronyms/acronyms.html"}
              className={"faa-link"}
              metricLog={"Link Footer Glossary of Terms"}
            >
              Glossary of Terms
            </ExternalLink>
            <ExternalLink
              href={"/api/airport-status-information"}
              className={"faa-link"}
              metricLog={"Link Footer Legacy XML"}
              openInNewTab={false}
            >
              XML
            </ExternalLink>
          </div>
          <div className="footer-column">
            <h3 className="footer-column-header">Visit Other FAA Sites</h3>
            <ExternalLink
              href={"https://tfmlearning.faa.gov"}
              className={"faa-link"}
              metricLog={"Link Footer TFM Learning"}
            >
              TFM Learning
            </ExternalLink>
            <ExternalLink href={"https://www.faa.gov/"} className={"faa-link"} metricLog={"Link Footer FAA Home"}>
              FAA Home
            </ExternalLink>
          </div>
          <div className="footer-column">
            <h3 className="footer-column-header">Contact Us</h3>
            <ExternalLink
              href={"https://www.faa.gov/contact/"}
              className={"faa-link"}
              metricLog={"Link Footer Contact FAA"}
            >
              Contact FAA
            </ExternalLink>
            <p className="contact-footer">Email: Fly-FAA@faa.gov</p>
            <a className="faa-link" target="_blank" rel="noopener noreferrer" href={UserGuide}>
              User Guide
            </a>
          </div>
        </div>
      </section>
      <section className="dot-footer">
        <div className="dot-logo-container">
          <DotLogo data-testid="dot-logo" />
          <address className="dot-address">
            <ExternalLink
              href="https://www.transportation.gov/"
              className={"dot-link"}
              metricLog={"Link Footer DOT Transportation"}
            >
              U.S. Department of Transportation
            </ExternalLink>
            <p>Federal Aviation Administration</p>
            <p>
              800 Independence Avenue, <abbr>SW</abbr>
            </p>
            <p>
              Washington, <abbr>DC</abbr> 20591
            </p>
            <p>
              (866) <span className="uppercase">tell</span>-<abbr>FAA</abbr> (<small>(866) 835-5322</small>)
            </p>
          </address>
        </div>
        <div className="footer-column">
          <h3 className="footer-column-header">Web Policies</h3>
          <a className="dot-link" target="_blank" rel="noreferrer noopener" href="https://www.faa.gov/privacy/">
            Privacy Policy
          </a>
          <a className="dot-link" target="_blank" rel="noreferrer noopener" href="https://www.faa.gov/accessibility/">
            Accessibility
          </a>
          {version && (
            <span className="version-style" data-testid="app-version">
              VERSION {version}
            </span>
          )}
        </div>
      </section>
    </footer>
  );
};

export default Footer;
