import React, { useContext } from "react";
import { FuelFlowAdvisoryDelayTime } from "../../../../types";
import "../../airporteventcards/airportEventCard.scss";
import Modal from "react-modal";
// import { postMetricLog } from "../../../../helpers/metricsHelpers";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import "./delayOverTimeModal.scss";
import { LocalTimeContext } from "../../../../AppContext";
import { toggleZuluAndLocalTime } from "../../../../helpers/dateHelper";
export interface DelayOverTimeModalProps {
  fuelFlowAdvisoryDelayTime: FuelFlowAdvisoryDelayTime;
  delayTableCaption: string;
  delayTableDescription: string;
}

export const getIntervals = (
  fuelFlowAdvisoryDelayTime: FuelFlowAdvisoryDelayTime,
  isLocalTime: boolean
): { timeInterval: string; averageDelay: number }[] => {
  const timeIntervals: { timeInterval: string; averageDelay: number }[] = [];

  let startTime = moment(fuelFlowAdvisoryDelayTime?.startTime).utc();
  let endOfHour = startTime.clone().endOf("hour");

  if (startTime.minute() % 15 !== 0) {
    if (startTime.minute() > 45) {
      startTime = startTime.add(60 - startTime.minute(), "minutes");
      endOfHour = startTime.clone().endOf("hour");
    } else {
      startTime = startTime.subtract(startTime.minute() % 15, "minutes");
    }
  }

  let intervalStartTime = startTime;
  let intervalEndTime = endOfHour;

  const calcIntervalAndAvgDelay = (totalDelay: number, avgBy: number) => {
    const averageDelay = totalDelay / avgBy;
    const timeInterval = `${toggleZuluAndLocalTime(intervalStartTime.toDate(), isLocalTime)}-${toggleZuluAndLocalTime(
      intervalEndTime.toDate(),
      isLocalTime
    )}`;
    timeIntervals.push({ timeInterval, averageDelay });
    intervalStartTime = intervalEndTime.clone().add(1, "minute");
    intervalEndTime = intervalStartTime.clone().endOf("hour");
  };

  function calculateDelaysWithStartTimeMinIsZero() {
    for (let j = 0; j < fuelFlowAdvisoryDelayTime.dasDelays.dasDelay.length; j += 4) {
      let totalDelay = 0;
      const delayLength = fuelFlowAdvisoryDelayTime.dasDelays.dasDelay.length;
      const leftOverQuarters = delayLength - j < 4 ? delayLength - j : 4;

      if (leftOverQuarters < 4) {
        intervalEndTime = intervalStartTime.clone().add(leftOverQuarters * 15, "minutes");
      }

      fuelFlowAdvisoryDelayTime.dasDelays.dasDelay.slice(j, j + 4).forEach((dasDelay) => {
        totalDelay += dasDelay.delay;
      });

      calcIntervalAndAvgDelay(totalDelay, leftOverQuarters);
    }
  }

  function calculateDelaysWithStartMinNotZero() {
    const splitIndex = (60 - startTime.minute()) / 15;
    let totalDelay = 0;

    fuelFlowAdvisoryDelayTime.dasDelays.dasDelay.slice(0, splitIndex).forEach((dasDelay) => {
      totalDelay += dasDelay.delay;
    });
    calcIntervalAndAvgDelay(totalDelay, splitIndex);

    const lastLoopIndex = fuelFlowAdvisoryDelayTime.dasDelays.dasDelay.length - splitIndex + 1;
    const dasDelayLength = fuelFlowAdvisoryDelayTime.dasDelays.dasDelay.length;
    const remainingQuarters = (dasDelayLength - splitIndex) % 4;
    const indexOfFirstRemainingQuarter = dasDelayLength - remainingQuarters;

    if (dasDelayLength - splitIndex < 4) {
      let delaySum = 0;
      const remainingQrtr = dasDelayLength - splitIndex;
      intervalEndTime = intervalStartTime.clone().add(remainingQrtr * 15, "minutes");
      fuelFlowAdvisoryDelayTime.dasDelays.dasDelay.slice(splitIndex, dasDelayLength).forEach((dasDelay) => {
        delaySum += dasDelay.delay;
      });
      calcIntervalAndAvgDelay(delaySum, remainingQrtr);
    } else {
      for (let i = splitIndex; i <= lastLoopIndex; i += 4) {
        let totalDelay = 0;

        let dasDelaysPerHour = 4;

        if (i === indexOfFirstRemainingQuarter) {
          dasDelaysPerHour = (fuelFlowAdvisoryDelayTime.dasDelays.dasDelay.length - splitIndex) % 4;
          intervalEndTime = intervalStartTime.clone().add(remainingQuarters * 15, "minutes");
        }

        fuelFlowAdvisoryDelayTime.dasDelays.dasDelay.slice(i, i + 4).forEach((dasDelay) => {
          totalDelay += dasDelay.delay;
        });

        calcIntervalAndAvgDelay(totalDelay, dasDelaysPerHour);
      }
    }
  }

  if (startTime.minute() !== 0) {
    calculateDelaysWithStartMinNotZero();
  } else if (startTime.minute() === 0) {
    calculateDelaysWithStartTimeMinIsZero();
  }

  return timeIntervals;
};

const DelayOverTimeModal: React.FC<DelayOverTimeModalProps> = ({
  fuelFlowAdvisoryDelayTime,
  delayTableCaption,
  delayTableDescription,
}) => {
  const { isLocalTime } = useContext(LocalTimeContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    // postMetricLog("Delay Trend Button");
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div
      data-testid={`${fuelFlowAdvisoryDelayTime?.delayProgramType}-${fuelFlowAdvisoryDelayTime?.controlElement}-delay-trend`}
    >
      <button
        className="delay-trend-button"
        data-testid={`button-${fuelFlowAdvisoryDelayTime?.controlElement}-delay-trend`}
        onClick={openModal}
      >
        <FontAwesomeIcon icon={faClock} />
        Delay Trend
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="delay-modal"
        overlayClassName="delay-modal-overlay"
        contentLabel="Delay Over Time Modal"
        ariaHideApp={false}
      >
        <button
          className="delay-table-close"
          data-testid={`button-${fuelFlowAdvisoryDelayTime?.controlElement}-close-delay-trend`}
          aria-label={"Close Delay Table"}
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>Delay Trend</h2>
        <div>
          <p className="delay-table-description">{delayTableDescription}</p>
          <table className="delay-table">
            <caption className="delay-table-caption">{delayTableCaption}</caption>
            <tbody>
              <tr>
                <th className="delay-table-element">Arrival Time Interval</th>
                <th className="delay-table-element">Avg Delay (min)</th>
              </tr>
              {getIntervals(fuelFlowAdvisoryDelayTime, isLocalTime).map((delayWithTime, index) => (
                <tr key={`${index}delay-table-row`} className="delay-table-element">
                  <td className="delay-table-element">{delayWithTime.timeInterval}</td>
                  <td className="delay-table-element">{Math.round(delayWithTime.averageDelay)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};
Modal.setAppElement("body");
export default DelayOverTimeModal;
