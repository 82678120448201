import React from "react";
import MapViewCard from "../../components/cards/mapviewcard/MapViewCard";
import "./mapView.scss";

export interface MapViewProps {
  showArtcc: boolean;
}

const MapView: React.FC<MapViewProps> = ({ showArtcc }) => {
  return (
    <div className="main-map-container" data-testid="main-map-container">
      <MapViewCard showArtccBoundaries={showArtcc} />
    </div>
  );
};

export default MapView;
