import React from "react";
import { Header } from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./pageNotFound.scss";
import "../home/home.scss";
import ErrorImage from "../../static/404-error.png";

interface PageNotFoundProps {
  build?: { version?: string };
}

const PageNotFound: React.FC<PageNotFoundProps> = ({ build }) => {
  return (
    <div className="app-container">
      <Header lastUpdatedTime={new Date()} />
      <div className="error-page-container">
        <div>
          <img className="error-image" src={ErrorImage} alt="Page Not Found Error" />
        </div>
        <div className="error-container">
          <div className="error-code">404</div>
          <div className="error-title">Page Not Found</div>
          <div className="error-message">Try refreshing the page, or going back and attempting the action again.</div>
        </div>
      </div>
      <Footer version={build?.version} />
    </div>
  );
};

export default PageNotFound;
