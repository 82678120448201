import React from "react";
import "./badge.scss";

interface BadgeProps {
  className: string;
  text: string;
  identifier: string;
}

const Badge: React.FC<BadgeProps> = ({ className, text, identifier }) => (
  <span className={`${className}`} data-testid={`${identifier}-badge`}>
    {text}
  </span>
);
export default Badge;
