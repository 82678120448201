import React from "react";
import "./flyout-menu.scss";
import { HeaderLinks } from "./HeaderLinks";

interface FlywayMenuProps {
  showMenu: boolean;
  handleClose: () => void;
}

export const FlyoutMenu: React.FC<FlywayMenuProps> = ({ showMenu, handleClose }) => {
  return (
    <nav className={showMenu ? "flyout-menu" : "flyout-menu-closed"} data-testid="flyout-menu">
      <div className="flyout-close-text" onClick={handleClose}>
        Close
      </div>
      <div className="flyout-link-container">
        <div className="flyout-links">
          <h3 className="flyout-title">Links</h3>
          <HeaderLinks />
        </div>
      </div>
    </nav>
  );
};

export default FlyoutMenu;
