import moment from "moment";
import { DateTime } from "luxon";

export const formatDateToString = (date: Date | undefined) => {
  if (date) {
    return moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "";
  }
};

export const toggleZuluAndLocalTime = (
  date: Date | undefined,
  toLocal: boolean = false,
  isHeaderTime: boolean = false
) => {
  if (date) {
    const dateMoment = moment(date, "YYYY-MM-DD'T'HH:mm:ssZ");

    const dt = DateTime.local(
      dateMoment.year(),
      parseInt(dateMoment.format("MM")),
      parseInt(dateMoment.format("DD")),
      dateMoment.hour(),
      dateMoment.minute(),
      dateMoment.second()
    );

    const localTime = dt.toLocal().toFormat(isHeaderTime ? "DD tt ZZZZ" : "t ZZZZ");
    const utcTime = dt.toUTC().toFormat(isHeaderTime ? "DD HH:mm:ss" : "HHmm") + (isHeaderTime ? " Z" : "Z");

    return toLocal ? localTime : utcTime;
  } else {
    return "";
  }
};

export const formatDateToDD = (date: Date | undefined, toLocal: boolean = false) => {
  if (date) {
    const dateMoment = moment(date);
    return toLocal ? dateMoment.format("DD") : dateMoment.utc().format("DD");
  } else {
    return "";
  }
};

export const formatDateToTimeString = (date: Date | undefined) => {
  if (date) {
    return moment(date).utc().format("MMM D, YYYY HH:mm:ss");
  } else {
    return "";
  }
};

export const formatDateToLocalTimeString = (date: Date | undefined) => {
  if (date) {
    return moment(date).local().format("MMM D, YYYY h:mm:ss A");
  } else {
    return "";
  }
};

export const now = (): Date => {
  return new Date(Date.now());
};

export const secondsElapsed = (currentDate: Date, previousDate: Date): number => {
  return (currentDate.getTime() - previousDate.getTime()) / 1000;
};

export const stringHHmmToDate = (date: Date): Date => {
  return moment(date, "HHmm").toDate();
};
