import React from "react";
import { GroundStop } from "../../../../types";
import "../airportEventCard.scss";
import { TimeRange } from "../../shared/timerange/TimeRange";
import ProgramScope from "../../shared/programscope/ProgramScope";
import AdvisoryLink from "../../shared/advisorylink/AdvisoryLink";
import ScopeMapModal from "../../shared/scopemapmodal/ScopeMapModal";

export interface GroundStopBlockProps {
  groundStop: GroundStop;
  showDetails: boolean;
  airportLongName?: string;
  latitude?:string;
  longitude?:string;
}

const getProbabilityOfExtensionDisplayText = (probabilityOfExtension: string): string => {
  if (probabilityOfExtension === "LOW") {
    return `Probability of extension is low (less than 30%).`;
  } else if (probabilityOfExtension === "MEDIUM") {
    return `Probability of extension is medium (30-60%).`;
  } else if (probabilityOfExtension === "HIGH") {
    return `Probability of extension is high (greater than 60%).`;
  }
  return "";
};

const GroundStopBlock: React.FC<GroundStopBlockProps> = ({ groundStop, showDetails, airportLongName, latitude, longitude }) => (
  <div className="airport-card-block" data-testid={`${groundStop.airportId}-ground-stop-block`} aria-label={`${groundStop.airportId}-ground-stop-block`}>
    <div className="airport-card-content">
      <p className="event-type high-severe">Ground Stop</p>
      <TimeRange startTime={groundStop.startTime} endTime={groundStop.endTime} />
    </div>
    <div className="airport-card-content">
      {!showDetails && <p>{groundStop.impactingCondition && `Due to ${groundStop.impactingCondition}.`}</p>}
      {showDetails && (
        <p>
          {groundStop.impactingCondition &&
            `Departures to ${
              airportLongName || groundStop.airportId
            } are grounded due to ${groundStop.impactingCondition.toLowerCase()}.`}
        </p>
      )}
      {!showDetails && <AdvisoryLink href={groundStop.advisoryUrl} eventType="GroundStop"/>}
    </div>
    {showDetails && (
      <>
        <p>
          {groundStop.probabilityOfExtension &&
            `${getProbabilityOfExtensionDisplayText(groundStop.probabilityOfExtension)}`}
        </p>

        <ProgramScope
          identifier={`${groundStop.airportId}-ground-stop`}
          facilities={groundStop.includedFacilities}
          includedFlights={groundStop.includedFlights}
          eventType="GroundStop"
        />
      </>
    )}
    <div className="airport-links-container">
      {showDetails && groundStop.advisoryUrl && (
       <div className="airport-card-link">
      <AdvisoryLink href={groundStop.advisoryUrl} eventType="GroundStop"/>
       </div>
      )}

      {showDetails && (
       <div className="airport-card-link">
       <ScopeMapModal identifier={`${groundStop.airportId}-ground-stop`} airportId={groundStop.airportId} eventType={"Ground Stop"} facilities={groundStop.includedFacilities} latitude={latitude} longitude={longitude} />
      </div>
       )}
    </div>
  </div>
);
export default GroundStopBlock;
