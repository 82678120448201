import React, { ReactNode } from "react";
import "./sectionHeader.scss";

const SectionHeader = (props: { children: ReactNode }) => {
  const children = props.children;

  return (
    <h2 className="section-header" data-testid="section-header">
      {children}
    </h2>
  );
};

export default SectionHeader;
