import React from "react";
import { Card } from "../Card";
import { AirportEvent } from "../../../types";
import AirportClosureBlock from "./airportclosureblock/AirportClosureBlock";
import GroundStopBlock from "./groundstopblock/GroundStopBlock";
import GroundDelayProgramBlock from "./grounddelayprogramblock/GroundDelayProgramBlock";
import Badge from "../shared/badge/Badge";
import ArrivalDepartureDelayBlock from "./arrivaldeparturedelayblock/ArrivalDepartureDelayBlock";
import RunwayConfigBlock from "./runwayconfigblock/RunwayConfigBlock";
import DeicingBlock from "./deicingblock/DeicingBlock";
import FreeFormBlock from "./freeformblock/FreeFormBlock";

interface AirportCardProps {
  airportEvent: AirportEvent;
}

const AirportEventCard: React.FC<AirportCardProps> = ({ airportEvent }) => {
  const {
    airportClosure,
    freeForm,
    groundStop,
    groundDelay,
    arrivalDelay,
    departureDelay,
    deicing,
    airportConfig,
    airportLongName,
    latitude,
    longitude
  } = airportEvent;
  const [shouldShowDetails, setShouldShowDetails] = React.useState(false);

  return (
    <Card identifier={airportEvent.airportId}>
      <div className="airport-card-content">
        <div className="airport-card-header">
          <h3 className="airport-name" aria-label={airportEvent.airportId.split("").join(" ")}>
            {airportEvent.airportId}
          </h3>
          {airportEvent.updateText && (
            <Badge className="badge" text={airportEvent.updateText} identifier={airportEvent.airportId} />
          )}
        </div>
        <button
          className="view-details-button"
          data-testid={`card-${airportEvent.airportId}-view-details`}
          onClick={() => {
            setShouldShowDetails(!shouldShowDetails);
          }}
        >
          {shouldShowDetails ? "Hide Details" : "View Details"}
        </button>
      </div>
      {airportClosure && (
        <AirportClosureBlock
          airportClosure={airportClosure}
          airportLongName={airportLongName}
          showDetails={shouldShowDetails}
        />
      )}
      {freeForm && (
        <FreeFormBlock
        freeForm={freeForm}
        airportLongName={airportLongName}
        showDetails={shouldShowDetails}
        />
      )}
      {groundStop && (
        <GroundStopBlock groundStop={groundStop} airportLongName={airportLongName} showDetails={shouldShowDetails} latitude={latitude} longitude={longitude} />
      )}
      {groundDelay && (
        <GroundDelayProgramBlock
          groundDelayProgram={groundDelay}
          airportLongName={airportLongName}
          showDetails={shouldShowDetails}
          latitude={latitude}
          longitude={longitude}
        />
      )}
      {arrivalDelay && (
        <ArrivalDepartureDelayBlock
          arrivalDepartureDelay={arrivalDelay}
          airportLongName={airportLongName}
          showDetails={shouldShowDetails}
        />
      )}
      {departureDelay && (
        <ArrivalDepartureDelayBlock
          arrivalDepartureDelay={departureDelay}
          airportLongName={airportLongName}
          showDetails={shouldShowDetails}
        />
      )}
      {deicing && <DeicingBlock deicing={deicing} airportLongName={airportLongName} showDetails={shouldShowDetails} />}
      {shouldShowDetails && airportConfig && <RunwayConfigBlock {...airportConfig} />}
    </Card>
  );
};

export default AirportEventCard;
