import React, { useContext } from "react";
import { AirportEvent, AirportEventListObj } from "./../../types";
import "./dataTable.scss";
import { formatDateToDD, toggleZuluAndLocalTime } from "../../helpers/dateHelper";
import { LocalTimeContext } from "../../AppContext";
import AdvisoryLink from "../../components/cards/shared/advisorylink/AdvisoryLink";
import DelayOverTimeModal from "../../components/cards/shared/delayovertimemodal/DelayOverTimeModal";
import Badge from "../../components/cards/shared/badge/Badge";
import { Card } from "../../components/cards/Card";
import ScopeMapModal from "../../components/cards/shared/scopemapmodal/ScopeMapModal";

export interface DataTableProps {
  identifier: string;
  events: AirportEvent[];
}

const capitalizeFirstLetter = (text: string | undefined): string => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : "";
};

const ARRIVAL_DELAY = "Arrival Delay";
const DEPARTURE_DELAY = "Departure Delay";
const AIRPORT_CLOSURE = "Airport Closure";
const GROUND_DELAY = "Ground Delay";
const GROUND_STOP = "Ground Stop";
const DEICING = "Deicing";

const DataTable: React.FC<DataTableProps> = (props) => {
  const { isLocalTime } = useContext(LocalTimeContext);
  const tableOrder = [AIRPORT_CLOSURE, GROUND_STOP, GROUND_DELAY, ARRIVAL_DELAY, DEPARTURE_DELAY, DEICING];

  let airportEventList: AirportEventListObj[] = [];

  props.events.forEach((event) => {
    if (event.airportClosure) {
      const airportClosureObj = {} as AirportEventListObj;
      airportClosureObj.airportId = event.airportClosure.airportId;
      airportClosureObj.eventType = AIRPORT_CLOSURE;
      airportClosureObj.eventStartTime = event.airportClosure.startTime;
      airportClosureObj.eventEndTime = event.airportClosure.endTime;
      airportClosureObj.avgDelay = "";
      airportClosureObj.reason = "";
      if (event.airportClosure.text === "") {
        airportClosureObj.scope = "Closed";
      } else {
        airportClosureObj.scope = "Closed " + event.airportClosure.text;
      }
      airportClosureObj.runwayArrival = event.airportConfig ? event.airportConfig.arrivalRunwayConfig : "";
      airportClosureObj.runwayDeparture = event.airportConfig ? event.airportConfig.departureRunwayConfig : "";
      airportClosureObj.airportArrivalRate = event.airportConfig ? String(event.airportConfig.arrivalRate) : "";
      airportClosureObj.eventTypeStyle = "list-view-table-event-text-high";
      airportClosureObj.updateText = event.updateText;
      airportClosureObj.minutesRemaining = event.minutesRemaining;
      airportEventList.push(airportClosureObj);
    }
    if (event.freeForm) {
      const freeFormObj = {} as AirportEventListObj;
      freeFormObj.airportId = event.freeForm.airportId;
      freeFormObj.eventType = AIRPORT_CLOSURE;
      freeFormObj.eventStartTime = event.freeForm.startTime;
      freeFormObj.eventEndTime = event.freeForm.endTime;
      freeFormObj.avgDelay = "";
      freeFormObj.reason = "";
      if (event.freeForm.text === "") {
        freeFormObj.scope = "Closed";
      } else {
        freeFormObj.scope = "Closed " + event.freeForm.text;
      }
      freeFormObj.runwayArrival = event.airportConfig ? event.airportConfig.arrivalRunwayConfig : "";
      freeFormObj.runwayDeparture = event.airportConfig ? event.airportConfig.departureRunwayConfig : "";
      freeFormObj.airportArrivalRate = event.airportConfig ? String(event.airportConfig.arrivalRate) : "";
      freeFormObj.eventTypeStyle = "list-view-table-event-text-high";
      freeFormObj.updateText = event.updateText;
      freeFormObj.minutesRemaining = event.minutesRemaining;
      airportEventList.push(freeFormObj);
    }
    if (event.groundDelay) {
      const groundDelayObj = {} as AirportEventListObj;
      groundDelayObj.airportId = event.groundDelay.airportId;
      groundDelayObj.eventType = GROUND_DELAY;
      groundDelayObj.eventStartTime = event.groundDelay.startTime;
      groundDelayObj.eventEndTime = event.groundDelay.endTime;
      groundDelayObj.avgDelay = event.groundDelay.avgDelay + " mins";
      groundDelayObj.reason = capitalizeFirstLetter(event.groundDelay.impactingCondition);
      if (event.groundDelay.departureScope !== null) {
        groundDelayObj.departureScope = event.groundDelay.departureScope + " miles";
      }
      if (event.groundDelay.departureScope !== null && event.groundDelay.includedFacilities !== null) {
        groundDelayObj.departureScope = groundDelayObj.departureScope + " + ";
      }
      if (event.groundDelay.includedFacilities !== null) {
        groundDelayObj.includedFacilities = event.groundDelay.includedFacilities;
      }
      groundDelayObj.latitude = event.latitude;
      groundDelayObj.longitude= event.longitude;
      groundDelayObj.runwayArrival = event.airportConfig ? event.airportConfig.arrivalRunwayConfig : "";
      groundDelayObj.runwayDeparture = event.airportConfig ? event.airportConfig.departureRunwayConfig : "";
      groundDelayObj.airportArrivalRate = event.airportConfig ? String(event.airportConfig.arrivalRate) : "";
      groundDelayObj.eventTypeStyle = "list-view-table-event-text-medium";
      groundDelayObj.advisoryUrl = event.groundDelay.advisoryUrl;
      groundDelayObj.fuelFlowAdvisoryDelayTime = event.groundDelay.fuelFlowAdvisoryDelayTime;
      groundDelayObj.updateText = event.updateText;
      groundDelayObj.minutesRemaining = event.minutesRemaining;
      airportEventList.push(groundDelayObj);
    }
    if (event.groundStop) {
      const groundStopObj = {} as AirportEventListObj;
      groundStopObj.airportId = event.groundStop.airportId;
      groundStopObj.eventType = GROUND_STOP;
      groundStopObj.eventStartTime = event.groundStop.startTime;
      groundStopObj.eventEndTime = event.groundStop.endTime;

      if (event.groundStop.probabilityOfExtension === "LOW") {
        groundStopObj.avgDelay = "Low";
      } else if (event.groundStop.probabilityOfExtension === "MEDIUM") {
        groundStopObj.avgDelay = "Med";
      } else if (event.groundStop.probabilityOfExtension === "HIGH") {
        groundStopObj.avgDelay = "High";
      } else {
        groundStopObj.avgDelay = "";
      }

      groundStopObj.reason = capitalizeFirstLetter(event.groundStop.impactingCondition);
      groundStopObj.includedFacilities = event.groundStop.includedFacilities;
      groundStopObj.latitude = event.latitude;
      groundStopObj.longitude= event.longitude;
      groundStopObj.runwayArrival = event.airportConfig ? event.airportConfig.arrivalRunwayConfig : "";
      groundStopObj.runwayDeparture = event.airportConfig ? event.airportConfig.departureRunwayConfig : "";
      groundStopObj.airportArrivalRate = event.airportConfig ? String(event.airportConfig.arrivalRate) : "";
      groundStopObj.eventTypeStyle = "list-view-table-event-text-high";
      groundStopObj.advisoryUrl = event.groundStop.advisoryUrl;
      groundStopObj.updateText = event.updateText;
      groundStopObj.minutesRemaining = event.minutesRemaining;
      airportEventList.push(groundStopObj);
    }
    if (event.deicing) {
      const deicingObj = {} as AirportEventListObj;
      deicingObj.airportId = event.deicing.airportId;
      deicingObj.eventType = DEICING;
      deicingObj.eventDay = formatDateToDD(event.deicing.eventTime);
      deicingObj.eventTime = toggleZuluAndLocalTime(event.deicing.eventTime, isLocalTime);
      deicingObj.avgDelay = "";
      deicingObj.reason = "";
      deicingObj.scope = "";
      deicingObj.runwayArrival = event.airportConfig ? event.airportConfig.arrivalRunwayConfig : "";
      deicingObj.runwayDeparture = event.airportConfig ? event.airportConfig.departureRunwayConfig : "";
      deicingObj.airportArrivalRate = event.airportConfig ? String(event.airportConfig.arrivalRate) : "";
      deicingObj.eventTypeStyle = "list-view-table-event-text-low";
      deicingObj.updateText = event.updateText;
      deicingObj.minutesRemaining = event.minutesRemaining;
      airportEventList.push(deicingObj);
    }
    if (event.arrivalDelay) {
      const arrivalDelayObj = {} as AirportEventListObj;
      arrivalDelayObj.airportId = event.arrivalDelay.airportId;
      arrivalDelayObj.eventType = ARRIVAL_DELAY;
      arrivalDelayObj.eventTime = toggleZuluAndLocalTime(event.arrivalDelay.updateTime, isLocalTime);

      if (event.arrivalDelay.trend === "increasing") {
        arrivalDelayObj.avgDelay = "+" + event.arrivalDelay.averageDelay + " mins";
      }
      if (event.arrivalDelay.trend === "decreasing") {
        arrivalDelayObj.avgDelay = "-" + event.arrivalDelay.averageDelay + " mins";
      }

      arrivalDelayObj.reason = capitalizeFirstLetter(event.arrivalDelay.reason);
      arrivalDelayObj.scope = "";
      arrivalDelayObj.runwayArrival = event.airportConfig ? event.airportConfig.arrivalRunwayConfig : "";
      arrivalDelayObj.runwayDeparture = event.airportConfig ? event.airportConfig.departureRunwayConfig : "";
      arrivalDelayObj.airportArrivalRate = event.airportConfig ? String(event.airportConfig.arrivalRate) : "";
      arrivalDelayObj.eventTypeStyle = "list-view-table-event-text-low";
      arrivalDelayObj.updateText = event.updateText;
      arrivalDelayObj.minutesRemaining = event.minutesRemaining;
      airportEventList.push(arrivalDelayObj);
    }
    if (event.departureDelay) {
      const departureDelayObj = {} as AirportEventListObj;
      departureDelayObj.airportId = event.departureDelay.airportId;
      departureDelayObj.eventType = DEPARTURE_DELAY;
      departureDelayObj.eventTime = toggleZuluAndLocalTime(event.departureDelay.updateTime, isLocalTime);

      if (event.departureDelay.trend === "increasing") {
        departureDelayObj.avgDelay = "+" + event.departureDelay.averageDelay + " mins";
      }
      if (event.departureDelay.trend === "decreasing") {
        departureDelayObj.avgDelay = "-" + event.departureDelay.averageDelay + " mins";
      }

      departureDelayObj.reason = capitalizeFirstLetter(event.departureDelay.reason);
      departureDelayObj.scope = "";
      departureDelayObj.runwayArrival = event.airportConfig ? event.airportConfig.arrivalRunwayConfig : "";
      departureDelayObj.runwayDeparture = event.airportConfig ? event.airportConfig.departureRunwayConfig : "";
      departureDelayObj.airportArrivalRate = event.airportConfig ? String(event.airportConfig.arrivalRate) : "";
      departureDelayObj.eventTypeStyle = "list-view-table-event-text-low";
      departureDelayObj.updateText = event.updateText;
      departureDelayObj.minutesRemaining = event.minutesRemaining;
      airportEventList.push(departureDelayObj);
    }

    airportEventList.sort((a, b) => tableOrder.indexOf(a.eventType) - tableOrder.indexOf(b.eventType));
  });

  return (
    <Card additionalClassNames="list-view-card" identifier={props.identifier}>
      <table className="list-view-table" data-testid="list-view-table">
        <thead>
          <tr data-testid="list-view-column-header">
            <th className="list-view-table-header  list-view-table-padding-left list-view-table-fixed">
              <p className="list-view-header-text">Airport</p>
            </th>
            <th className="list-view-table-header "></th>
            <th className="list-view-table-header  list-view-table-padding-left list-view-table-fixed-second">
              <p className="list-view-header-text">Event</p>
            </th>
            <th className="list-view-table-header">
              <p className="list-view-header-text">Event Time</p>
            </th>
            <th className="list-view-table-header list-view-table-padding-right">
              <p className="list-view-header-text">Avg Delay/POE</p>
            </th>
            <th className="list-view-table-header">
              <p className="list-view-header-text">Reason</p>
            </th>
            <th className="list-view-table-header list-view-table-scope-padding">
              <p className="list-view-header-text">Scope</p>
            </th>
            <th className="list-view-table-header list-view-table-padding-right">
              <p className="list-view-header-text">Arrival Runway</p>
            </th>
            <th className="list-view-table-header">
              <p className="list-view-header-text">Departure Runway</p>
            </th>
            <th className="list-view-table-header list-view-table-padding-left">
              <p className="list-view-header-text">AAR</p>
            </th>
            <th className="list-view-table-header"></th>
          </tr>
        </thead>
        <tbody>
          {airportEventList.map((airportEvent, index) => {
            return (
              <tr
                className="list-view-table-row "
                data-testid={`${props.identifier}-table-row-${index}`}
                key={`${props.identifier}-row-${index}`}
              >
                <td className="list-view-table-airport-text">{airportEvent.airportId}</td>
                <td className="list-view-table-border">
                  {airportEvent.updateText && (
                    <Badge
                      className="list-view-table-badge"
                      text={airportEvent.updateText}
                      identifier={airportEvent.airportId}
                    />
                  )}
                </td>
                <td className={airportEvent.eventTypeStyle}>{airportEvent.eventType}</td>
                {(airportEvent.eventType === AIRPORT_CLOSURE ||
                  airportEvent.eventType === GROUND_STOP ||
                  airportEvent.eventType === GROUND_DELAY) && (
                  <td className="list-view-table-border list-view-table-padding-right">
                    <span>
                      {formatDateToDD(airportEvent.eventStartTime, isLocalTime)}/
                      {toggleZuluAndLocalTime(airportEvent.eventStartTime, isLocalTime)}
                    </span>
                    <span>
                      —{formatDateToDD(airportEvent.eventEndTime, isLocalTime)}/
                      {toggleZuluAndLocalTime(airportEvent.eventEndTime, isLocalTime)}
                    </span>
                  </td>
                )}
                {airportEvent.eventType === DEICING && (
                  <td className="list-view-table-border list-view-table-padding-right">
                    <span>
                      Started {airportEvent.eventDay}/{airportEvent.eventTime}
                    </span>
                  </td>
                )}
                {(airportEvent.eventType === DEPARTURE_DELAY || airportEvent.eventType === ARRIVAL_DELAY) && (
                  <td className="list-view-table-border list-view-table-padding-right">
                    <span>Updated {airportEvent.eventTime}</span>
                  </td>
                )}
                <td className="list-view-table-border">{airportEvent.avgDelay}</td>
                <td className="list-view-table-border list-view-table-padding-right">{airportEvent.reason}</td>

                {(airportEvent.eventType === AIRPORT_CLOSURE ||
                  airportEvent.eventType === DEICING ||
                  airportEvent.eventType === DEPARTURE_DELAY ||
                  airportEvent.eventType === ARRIVAL_DELAY) && (
                  <td className="list-view-table-border list-view-table-padding-right">
                    {airportEvent.scope}
                  </td>
                )}

                {airportEvent.eventType === GROUND_STOP && (
                  <td className="list-view-table-border list-view-table-padding-right">
                    {" "}
                    {airportEvent.includedFacilities?.join(" ")}
                  </td>
                )}

                {airportEvent.eventType === GROUND_DELAY && (
                  <td className="list-view-table-border list-view-table-padding-right">
                    {airportEvent.departureScope}
                    {airportEvent.includedFacilities?.join(" ")}
                  </td>
                )}

                <td className="list-view-table-border">{airportEvent.runwayArrival}</td>
                <td className="list-view-table-border">{airportEvent.runwayDeparture}</td>
                <td className="list-view-table-border list-view-table-padding-left">
                  {airportEvent.airportArrivalRate}
                </td>
                <div className="list-view-link-align">
                  <td className="list-view-table-border list-view-table-advisory-link">
                    <AdvisoryLink href={airportEvent.advisoryUrl} eventType={airportEvent.eventType}></AdvisoryLink>
                  </td>
                  {airportEvent.fuelFlowAdvisoryDelayTime ? (
                    <td className="list-view-table-border list-view-table-delay-trend-link">
                      <DelayOverTimeModal
                        fuelFlowAdvisoryDelayTime={airportEvent.fuelFlowAdvisoryDelayTime}
                        delayTableDescription={"Delay in minutes by each hour for this event"}
                        delayTableCaption={`${airportEvent.fuelFlowAdvisoryDelayTime.controlElement} Ground Delay Program`}
                      />
                    </td>
                  ) : (
                    <td className="list-view-table-border"></td>
                  )}
                  {airportEvent.eventType === GROUND_STOP && (
                  <td className="list-view-table-border list-view-table-scope-link ">
                  <ScopeMapModal identifier={`${airportEvent.airportId}-list-view`} airportId={airportEvent.airportId} eventType={GROUND_STOP}  latitude={airportEvent.latitude} longitude={airportEvent.longitude} facilities={airportEvent.includedFacilities}/>
                  </td>
                  )}

                   {airportEvent.eventType === GROUND_DELAY && (
                  <td className="list-view-table-border list-view-table-scope-link ">
                      <ScopeMapModal identifier={`${airportEvent.airportId}-list-view`} airportId={airportEvent.airportId} eventType={"Ground Delay"} latitude={airportEvent.latitude} longitude={airportEvent.longitude} facilities={airportEvent.includedFacilities !== undefined ? airportEvent.includedFacilities: null} nauticalMiles={airportEvent.departureScope !== null && airportEvent.departureScope !== undefined ? Number(airportEvent.departureScope?.split(" ")[0]) : null }/>
                  </td>
                  )}
                  
                </div>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export default DataTable;