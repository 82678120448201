import React, { useContext } from "react";
import { formatDateToDD, toggleZuluAndLocalTime } from "../../../../helpers/dateHelper";
import "./timeRange.scss";
import { LocalTimeContext } from "../../../../AppContext";

export interface TimeRangeProps {
  startTime?: Date;
  endTime?: Date;
}

export const TimeRange: React.FC<TimeRangeProps> = ({ startTime, endTime }) => {
  const { isLocalTime } = useContext(LocalTimeContext);

  return (
    <p className={"event-time-block"}>
      <span>{formatDateToDD(startTime, isLocalTime)}/</span>
      <span className="event-time">{toggleZuluAndLocalTime(startTime, isLocalTime)}</span>
      <span>—{formatDateToDD(endTime, isLocalTime)}/</span>
      <span className="event-time">{toggleZuluAndLocalTime(endTime, isLocalTime)}</span>
    </p>
  );
};
