import React from "react";
import { AirportClosure } from "../../../../types";
import "../airportEventCard.scss";
import { TimeRange } from "../../shared/timerange/TimeRange";

export interface AirportClosureBlockProps {
  airportClosure: AirportClosure;
  showDetails: boolean;
  airportLongName?: string;
}

const AirportClosureBlock: React.FC<AirportClosureBlockProps> = ({ airportClosure, showDetails, airportLongName }) => {
  return (
    <div className="airport-card-block" data-testid={`${airportClosure.airportId}-airport-closure-block`}>
      <div className="airport-card-content">
        <p className="event-type high-severe">Airport Closure</p>
        <TimeRange startTime={airportClosure.startTime} endTime={airportClosure.endTime} />
      </div>
      {!showDetails && <p>{airportClosure.text && `Closed ${airportClosure.text}`}</p>}
      {showDetails && (
        <p>
          {" "}
          {airportLongName} is closed {airportClosure.text}{" "}
        </p>
      )}
    </div>
  );
};
export default AirportClosureBlock;
