import React from "react";

export interface ProgramFacilitiesProps {
  identifier: string;
  facilities?: string[];
  includedFlights?: string;
  eventType: string;
}

const ProgramScope: React.FC<ProgramFacilitiesProps> = ({ facilities, includedFlights, identifier, eventType }) => {
  return (
    <div data-testid={`${identifier}-program-scope`}>
      {includedFlights && (
        <p>
          {includedFlights
            .replace("ONLY", "only")
            .replace("CARRIER", "Carrier")
            .replace("ALL", "All")
            .replace("CONTIGUOUS", "Contiguous")
            .trim() + " flights included."}
        </p>
      )}
      {eventType === "GroundStop" && facilities && facilities.length > 0 && (
        <p>Grounding applies to departures from {facilities.join(" ")}</p>
      )}
      {eventType === "GroundDelayProgram" && facilities && facilities.length > 0 && (
        <p>Delay is applied to departures from {facilities.join(" ")}</p>
      )}
      {eventType === "GroundDelayProgram" && <p>All departures receive expected departure clearance times (EDCTs)</p>}
    </div>
  );
};

export default ProgramScope;
