import React, { useState } from "react";

export const LocalTimeContext = React.createContext({
  isLocalTime: true,
  toggleLocalTime: () => {
    //do nothing.
  },
});

interface AppContextProps {
  children: React.ReactNode;
  initialLocalTime?: boolean;
}

const AppContext: React.FC<AppContextProps> = ({ children, initialLocalTime = true }) => {
  const [isLocalTime, setIsLocalTime] = useState(initialLocalTime);
  return (
    <LocalTimeContext.Provider
      value={{
        isLocalTime,
        toggleLocalTime: () => {
          setIsLocalTime(!isLocalTime);
        },
      }}
    >
      {children}
    </LocalTimeContext.Provider>
  );
};

export default AppContext;
