import React from "react";
// import { postMetricLog } from "../helpers/metricsHelpers";

interface ExternalLinkProps {
  href?: string;
  className?: string;
  metricLog: string;
  ariaLabel?: string;
  testId?: string;
  children: React.ReactNode;
  openInNewTab?: boolean;
  role?:string
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ openInNewTab = true, ...props }) => {
  return (
    <a
      className={props.className}
      target={openInNewTab ? "_blank" : ""}
      rel="noreferrer noopener"
      href={props.href}
      aria-label={props.ariaLabel}
      data-testid={props.testId}
      role={props.role}
      // onClick={() => postMetricLog(props.metricLog)}
    >
      {props.children}
    </a>
  );
};

export default ExternalLink;
