import React from "react";
import { GroundDelayProgram } from "../../../../types";
import "../airportEventCard.scss";
import { TimeRange } from "../../shared/timerange/TimeRange";
import ProgramScope from "../../shared/programscope/ProgramScope";
import AdvisoryLink from "../../shared/advisorylink/AdvisoryLink";
import DelayOverTimeModal from "../../shared/delayovertimemodal/DelayOverTimeModal";
import ScopeMapModal from "../../shared/scopemapmodal/ScopeMapModal";

export interface GroundDelayProgramBlockProps {
  groundDelayProgram: GroundDelayProgram;
  showDetails: boolean;
  airportLongName?: string;
  latitude?:string;
  longitude?:string;
}
const GroundDelayProgramBlock: React.FC<GroundDelayProgramBlockProps> = ({
  groundDelayProgram,
  showDetails,
  airportLongName,
  latitude,
  longitude
}) => {
  return (
    <div className="airport-card-block" data-testid={`${groundDelayProgram.airportId}-ground-delay-program-block`}>
      <div className="airport-card-content">
        <p className="event-type medium-severe">Ground Delay</p>
        <TimeRange startTime={groundDelayProgram.startTime} endTime={groundDelayProgram.endTime} />
      </div>
      <div className="airport-card-content">
        {!showDetails && (
          <p>
            {groundDelayProgram.impactingCondition &&
              `Avg. delay ${groundDelayProgram.avgDelay} mins. due to ${groundDelayProgram.impactingCondition}.`}
          </p>
        )}
        {showDetails && (
          <p>
            {groundDelayProgram.impactingCondition &&
              `Departures to ${airportLongName || groundDelayProgram.airportId} are delayed avg. ${
                groundDelayProgram.avgDelay
              } mins. due to ${groundDelayProgram.impactingCondition.toLowerCase()}.`}
          </p>
        )}
        {!showDetails && <AdvisoryLink href={groundDelayProgram.advisoryUrl} eventType="GroundDelayProgram"/>}
      </div>
      {showDetails && (
        <>
          <p>
            {groundDelayProgram.departureScope &&
              `Delay assigned to departures within ${groundDelayProgram.departureScope} nautical miles`}
          </p>
          <ProgramScope
            facilities={groundDelayProgram.includedFacilities}
            includedFlights={groundDelayProgram.includedFlights}
            identifier={`${groundDelayProgram.airportId}-ground-delay`}
            eventType="GroundDelayProgram"
          /> 
    <div className="airport-links-container">
      {showDetails && groundDelayProgram.advisoryUrl && (
       <div className="airport-card-link">
      <AdvisoryLink href={groundDelayProgram.advisoryUrl} eventType="GroundDelayProgram"/>
       </div>
      )}
      {groundDelayProgram.fuelFlowAdvisoryDelayTime && (
        <div className="airport-card-link">
              <DelayOverTimeModal
                fuelFlowAdvisoryDelayTime={groundDelayProgram.fuelFlowAdvisoryDelayTime}
                delayTableDescription={"Delay in minutes by each hour for this event"}
                delayTableCaption={`${groundDelayProgram.fuelFlowAdvisoryDelayTime.controlElement} Ground Delay Program`}
                />
                </div>
            )}

      {showDetails && (
       <div className="airport-card-link">
       <ScopeMapModal identifier={`${groundDelayProgram.airportId}-ground-delay`} airportId={groundDelayProgram.airportId} eventType={"Ground Delay"}  latitude={latitude} longitude={longitude} facilities={groundDelayProgram.includedFacilities} nauticalMiles={groundDelayProgram.departureScope}/>
      </div>
       )}
    </div>
        </>
      )}
    </div>
  );
};
export default GroundDelayProgramBlock;
