import React, { useEffect } from "react";
import Acceptance from "./Acceptance";
import restHelpers, { API } from "../../../helpers/restHelpers";
import { XmlApiResponse } from "../../../types";
import { getAndUpdateState } from "../../../helpers/stateHelpers";
import "./acceptance.scss";

const AcceptancePage: React.FC = () => {
  const [apiHasBeenCalled, setApiHasBeenCalled] = React.useState<boolean>(false);

  const [apiIsUp, setIsItUp] = React.useState<string>("");
  const [database, setDatabase] = React.useState<string>("");
  const [groundStops, setGroundStops] = React.useState<XmlApiResponse[]>([]);
  const [airspaceFlowProgram, setAirspaceFlowProgram] = React.useState<XmlApiResponse[]>([]);
  const [groundDelayProgram, setGroundDelayProgram] = React.useState<XmlApiResponse[]>([]);
  const [ctops, setCtops] = React.useState<XmlApiResponse[]>([]);
  const [generalAdvisories, setGeneralAdvisories] = React.useState<XmlApiResponse[]>([]);
  const [flowConstrainedAreas, setFlowConstrainedAreas] = React.useState<XmlApiResponse[]>([]);
  const [restrictions, setRestrictions] = React.useState<XmlApiResponse[]>([]);
  const [reroutes, setReroutes] = React.useState<XmlApiResponse[]>([]);
  const [deicing, setDeicing] = React.useState<XmlApiResponse[]>([]);
  const [airportClosures, setAirportClosures] = React.useState<XmlApiResponse[]>([]);
  const [fadts, setFadts] = React.useState<XmlApiResponse[]>([]);
  const [params, setParams] = React.useState<XmlApiResponse[]>([]);
  const [airportConfigs, setAirportConfigs] = React.useState<XmlApiResponse[]>([]);
  const [freeForm, setFreeForm] = React.useState<XmlApiResponse[]>([]);
  const [jmsHealthy, setJmsHealthy] = React.useState<boolean>(true);

  const getSwimMessages = (api: API) => {
    getAndUpdateState(api + "?msgType=GS", setGroundStops);
    getAndUpdateState(api + "?msgType=AFP", setAirspaceFlowProgram);
    getAndUpdateState(api + "?msgType=GDP", setGroundDelayProgram);
    getAndUpdateState(api + "?msgType=CTOP", setCtops);
    getAndUpdateState(api + "?msgType=GADV", setGeneralAdvisories);
    getAndUpdateState(api + "?msgType=FXA", setFlowConstrainedAreas);
    getAndUpdateState(api + "?msgType=RSTR", setRestrictions);
    getAndUpdateState(api + "?msgType=REROUTE", setReroutes);
    getAndUpdateState(api + "?msgType=DICE", setDeicing);
    getAndUpdateState(api + "?msgType=FADT", setFadts);
    getAndUpdateState(api + "?msgType=PARAM", setParams);
    getAndUpdateState(api + "?msgType=APTC", setAirportConfigs);
    getAndUpdateState(api + "?msgType=AIRPORT_CLOSURE", setAirportClosures);
    getAndUpdateState(api + "?msgType=FREE_FORM", setFreeForm);
  };

  useEffect(() => {
    if (!apiHasBeenCalled) {
      restHelpers
        .get(API.Health)
        .then((json: any) => {
          setIsItUp(json.status);
          const components = json.components || {};
          const dbJson = components.db || {};
          const dbDetails = dbJson.details || {};
          const db = dbDetails.database || "ERROR";
          const jmsJson = components.jms || {};
          setJmsHealthy(jmsJson.status === "UP");
          setDatabase(db);
        })
        .catch((json: any) => {
          setIsItUp("Down");
          setJmsHealthy(false);
          setDatabase("ERROR");
        });
      getSwimMessages(API.RecentSwimMessages);

      setApiHasBeenCalled(true);
    }
  }, [apiHasBeenCalled]);

  return (
    <div className="acceptance">
      <header className="acceptance-header">Faalcon</header>
      <button
        className="load-button"
        onClick={() => {
          getSwimMessages(API.SwimMessages);
        }}
      >
        Load All
      </button>

      <button
        className="load-button"
        onClick={() => {
          getSwimMessages(API.OldestSwimMessages);
        }}
      >
        Load Oldest
      </button>

      <button
        className="load-button"
        onClick={() => {
          getSwimMessages(API.RecentSwimMessages);
        }}
      >
        Load Recent
      </button>
      <Acceptance
        apiIsUp={apiIsUp}
        database={database}
        jmsHealthy={jmsHealthy}
        airspaceFlowPrograms={airspaceFlowProgram}
        groundStops={groundStops}
        groundDelayPrograms={groundDelayProgram}
        ctops={ctops}
        generalAdvisories={generalAdvisories}
        flowConstrainedAreas={flowConstrainedAreas}
        restrictions={restrictions}
        reroutes={reroutes}
        deicing={deicing}
        airportClosures={airportClosures}
        fadts={fadts}
        params={params}
        airportConfigs={airportConfigs}
        freeForm={freeForm}
      />
    </div>
  );
};

export default AcceptancePage;
