import React, { useContext, useEffect, useState } from "react";
import { toggleZuluAndLocalTime } from "../../helpers/dateHelper";
import { LocalTimeContext } from "../../AppContext";

const TickingClock = () => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date(Date.now()));
  const { isLocalTime } = useContext(LocalTimeContext);

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date(Date.now())), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={"current-time incrementing-time"} data-testid="current-time">
      Current: {toggleZuluAndLocalTime(currentTime, isLocalTime, true)}
    </div>
  );
};

export default TickingClock;
