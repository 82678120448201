import React, { useContext, useEffect, useRef, useState } from "react";
import { isIE } from "react-device-detect";
import { LocalTimeContext } from "../../AppContext";
import ExternalLink from "../../components/ExternalLink";
import Footer from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import { now } from "../../helpers/dateHelper";
// import { logTimeOnPageMetric, logUserAgentMetrics, postMetricLog } from "../../helpers/metricsHelpers";
import restHelpers, { API } from "../../helpers/restHelpers";
import ArtccDisableButton from "../../static/Artcc-button-disable.png";
import ArtccEnableButton from "../../static/Artcc-button-enable.png";
import { ReactComponent as DesktopLegend } from "../../static/Desktop-legend.svg";
import { ReactComponent as Local } from "../../static/Local-time-logo.svg";
import LegendButton from "../../static/Mobile-legend-button.png";
import { ReactComponent as Zulu } from "../../static/Zulu-time-logo.svg";
import ListView from "../listview/ListView";
import LegendModal from "../mapview/LegendModal";
import MapView from "../mapview/MapView";
import TileView from "../tileview/TileView";
import "./home.scss";

interface HomeProps {
  git?: { closest?: { tag?: { name?: string } } };
  build?: { version?: string };
  listViewRoute?: boolean;
  mapViewRoute?: boolean;
  zulu?: boolean;
}

const Home: React.FC<HomeProps> = ({ git, listViewRoute, mapViewRoute, zulu }) => {
  const [lastUpdatedTime, setLastUpdatedTime] = useState<Date>();
  // const [userArriveTime, setUserArriveTime] = useState<Date>(now());
  const { isLocalTime, toggleLocalTime } = useContext(LocalTimeContext);
  const [mapView, setMapView] = React.useState(mapViewRoute);
  const [listView, setListView] = React.useState(listViewRoute);
  const [mapViewArtcc, setMapViewArtcc] = React.useState(Boolean(JSON.parse(localStorage.getItem('showArtcc') !== null || undefined ? localStorage.getItem('showArtcc') as string : "false")));
  const intervalRef = useRef();

  const getLastUpdatedTime = () => {
    restHelpers
      .get(API.Health)
      .then((json: any) => {
        setLastUpdatedTime(now());
      })
      .catch((json: any) => ({}));
  };

  useEffect(() => {
    // setUserArriveTime(now());
    setLastUpdatedTime(now());
    // logUserAgentMetrics();
    if (zulu === true) {
      toggleLocalTime();
      // eslint-disable-next-line
      zulu = false;
    }

    // @ts-ignore
    intervalRef.current = setInterval(getLastUpdatedTime, 60000);

    return () => clearInterval(intervalRef.current);
  }, []);

  // window.onbeforeunload = () => {
  //   logTimeOnPageMetric(userArriveTime);
  // };

  function updateLastUpdatedTime() {
    setLastUpdatedTime(now());
    clearInterval(intervalRef.current);
    // @ts-ignore
    intervalRef.current = setInterval(getLastUpdatedTime, 60000);

    return () => clearInterval(intervalRef.current);
  }

  return (
    <div className="home-container" id="app-base">
      <div>
        <Header lastUpdatedTime={lastUpdatedTime} />
        <main className="home">
          <div className={isIE ? "home-buttons-container-internet-explorer" : "home-buttons-container"}>
            <div className="view-navigation-container">
              {listView ? (
                <button
                  className="view-navigation-button-active"
                  onClick={() => {
                    setListView(true);
                    setMapView(false);
                    updateLastUpdatedTime();
                    // postMetricLog("Link Show List View");
                  }}
                  data-testid="list-view-button"
                >
                  List View
                </button>
              ) : (
                <button
                  className="view-navigation-button"
                  onClick={() => {
                    setListView(true);
                    setMapView(false);
                    updateLastUpdatedTime();
                    // postMetricLog("Link Show List View");
                    if (isLocalTime === true) {
                      window.location.href = "/list";
                    } else if (isLocalTime === false) {
                      window.location.href = "/list-zulu";
                    }
                  }}
                  data-testid="list-view-button"
                >
                  List View
                </button>
              )}

              {!listView && !mapView ? (
                <button
                  className="view-navigation-button-active"
                  onClick={() => {
                    setMapView(false);
                    setListView(false);
                    updateLastUpdatedTime();
                    // postMetricLog("Link Show Tile View");
                  }}
                  data-testid="tile-view-button"
                >
                  Tile View
                </button>
              ) : (
                <button
                  className="view-navigation-button"
                  onClick={() => {
                    setMapView(false);
                    setListView(false);
                    updateLastUpdatedTime();
                    // postMetricLog("Link Show Tile View");
                    if (isLocalTime === true) {
                      window.location.href = "/";
                    } else if (isLocalTime === false) {
                      window.location.href = "/zulu";
                    }
                  }}
                  data-testid="tile-view-button"
                >
                  Tile View
                </button>
              )}

              {mapView ? (
                <button
                  className="view-navigation-button-active"
                  onClick={() => {
                    setMapView(true);
                    setListView(false);
                    updateLastUpdatedTime();
                    // postMetricLog("Link Show Map View");
                  }}
                  data-testid="map-view-button"
                >
                  Map View
                </button>
              ) : (
                <button
                  className="view-navigation-button"
                  onClick={() => {
                    setMapView(true);
                    setListView(false);
                    updateLastUpdatedTime();
                    // postMetricLog("Link Show Map View");
                    if (isLocalTime === true) {
                      window.location.href = "/map";
                    } else if (isLocalTime === false) {
                      window.location.href = "/map-zulu";
                    }
                  }}
                  data-testid="map-view-button"
                >
                  Map View
                </button>
              )}
            </div>
            <div className="local-time">
              <label onClick={() => toggleLocalTime()} aria-label="local-time-toggle" data-testid="local-time-toggle">
                {isLocalTime ? <Zulu /> : <Local />}
              </label>
            </div>
          </div>
          <div className="map-view-buttons-div">
            {mapView && (
              <div id="legend-div" className="map-legend">
                <div id="mobile-legend" className="mobile-legend">
                  {" "}
                  <LegendModal />
                </div>
                <DesktopLegend className="desktop-legend" />
              </div>
            )}
            {mapView && (
              <div id="ie-legend-div" className="ie-map-legend">
                <ExternalLink
                  href={"/nasMapSymbols/Map_Legend_Mobile_View.png"}
                  metricLog={"Internet Explorer Map Legend"}
                  testId={"map-legend-link"}
                  ariaLabel={"Internet Explorer Map Legend Clickable Link"}
                  openInNewTab={true}
                  className={"map-legend-link"}
                >
                  <img className="ie-map-legend-button" src={LegendButton} alt="Button to view pop-up legend" />{" "}
                </ExternalLink>
              </div>
            )}
            {mapView && !mapViewArtcc && (
              <div id="map-artcc-button-div" className="map-artcc-button-div">
                <input
                  type="image"
                  className="map-artcc-button"
                  src={ArtccEnableButton}
                  alt="Button to enable ARTCC boundaries on map"
                  onClick={() => {
                    setMapViewArtcc(!mapViewArtcc);
                    updateLastUpdatedTime();
                  }}
                />{" "}
              </div>
            )}
            {mapView && mapViewArtcc && (
              <div id="map-artcc-button-div" className="map-artcc-button-div">
                <input
                  type="image"
                  className="map-artcc-button"
                  src={ArtccDisableButton}
                  alt="Button to enable ARTCC boundaries on map"
                  onClick={() => {
                    setMapViewArtcc(!mapViewArtcc);
                    updateLastUpdatedTime();
                  }}
                />
              </div>
            )}
          </div>

          {!mapView && !listView && <TileView />}
          {!mapView && listView && <ListView />}
          {mapView && !mapViewArtcc && <MapView showArtcc={mapViewArtcc} />}
          {mapView && mapViewArtcc && <MapView showArtcc={mapViewArtcc} />}
        </main>
      </div>
      <Footer version={git?.closest?.tag?.name!} />
    </div>
  );
};

export default Home;
