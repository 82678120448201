async function get(url: string) {
  const result = await fetch(url);
  if (result.status >= 400) {
    return Promise.reject(result);
  }
  return await result.json();
}

async function post(url: string, data: string) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  });
  return response.json();
}

const RestHelpers = {
  get,
  post,
};

export default RestHelpers;

export enum API {
  RelevantAirports = "/api/relevant-airports",
  PacingAirports = "/api/pacing-airports",
  Restrictions = "/api/restrictions",
  SwimMessages = "/api/swim-messages",
  RecentSwimMessages = "/api/swim-messages/recent",
  OldestSwimMessages = "/api/swim-messages/oldest",
  GeneralAdvisories = "/api/general-advisories",
  FlowConstrainedAreas = "/api/flow-constrained-areas",
  Health = "/actuator/health",
  DeadMessages = "/api/dead-messages",
  AirportEvents = "/api/airport-events",
  OperationsPlan = "/api/operations-plan",
  EnrouteEvents = "/api/enroute-events",
  MetricLog = "/api/metric-log",
  ActuatorInfo = "/actuator/info",
  ArtccBoundaries = "/api/artcc-boundaries",
  AtcsccAnnouncements = "/api/miscellaneous-info",
}
