import React, { useEffect } from "react";
import { getAndUpdateState } from "../../../helpers/stateHelpers";
import { GeneralAdvisory, XmlApiResponse } from "../../../types";
import { API } from "../../../helpers/restHelpers";
import XmlTable from "../../../components/XmlTable";
import { formatDateToString } from "../../../helpers/dateHelper";

const formatAdvisory = (generalAdvisories: GeneralAdvisory[]): XmlApiResponse[] => {
  return generalAdvisories.map((generalAdvisory) => {
    const formattedGeneralAdvisory = `
            ${generalAdvisory.advisoryTitle}
            MESSAGE: ${generalAdvisory.advisoryText}
            EFFECTIVE TIME: ${formatDateToString(generalAdvisory.startTime)} - ${formatDateToString(
      generalAdvisory.endTime
    )}
            SIGNATURE: ${formatDateToString(generalAdvisory.sourceTimeStamp)}
        `;
    return {
      id: generalAdvisory.id,
      xml: formattedGeneralAdvisory,
      createdAt: generalAdvisory.createdAt,
    };
  });
};

const GeneralAdvisories: React.FC = () => {
  const [generalAdvisories, setGeneralAdvisories] = React.useState<GeneralAdvisory[]>([]);

  useEffect(() => {
    getAndUpdateState(API.GeneralAdvisories, setGeneralAdvisories);
  }, []);

  return (
    <div>
      <XmlTable
        className={"general-advisories-table"}
        tableTitle={"General Advisories"}
        tableValues={formatAdvisory(generalAdvisories)}
        displayXmlWithFormatting={true}
      />
    </div>
  );
};

export default GeneralAdvisories;
