import React, { useContext } from "react";
import { Deicing } from "../../../../types";
import "../airportEventCard.scss";
import { formatDateToDD, toggleZuluAndLocalTime } from "../../../../helpers/dateHelper";
import { LocalTimeContext } from "../../../../AppContext";

export interface DeicingBlockProps {
  deicing: Deicing;
  showDetails: boolean;
  airportLongName?: string;
}
const DeicingBlock: React.FC<DeicingBlockProps> = ({ deicing, showDetails, airportLongName }) => {
  const { isLocalTime } = useContext(LocalTimeContext);
  return (
    <div className="airport-card-block" data-testid={`${deicing.airportId}-deicing-block`}>
      <div className="airport-card-content">
        <p className="event-type">Deicing </p>
        <p className="event-time-block">
          <span>Started </span>
          <span>{formatDateToDD(deicing.eventTime, isLocalTime)}/</span>
          <span className="event-time">{toggleZuluAndLocalTime(deicing.eventTime, isLocalTime)} </span>
        </p>
      </div>
      <div>
        {showDetails && (
          <p>
            {`Aircraft departing from ${
              airportLongName || deicing.airportId
            } are being sprayed with deicing fluid to remove snow and ice.`}
          </p>
        )}
      </div>
    </div>
  );
};
export default DeicingBlock;
