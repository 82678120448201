import React, { useEffect, useRef } from "react";
import { loadModules } from "esri-loader";
import "./airspaceFlowProgramMap.scss";
import {
  ArtccBoundary,
  Circle as CircleType,
  Line as LineType,
  PacingAirport,
  Polygon as PolygonType,
  FcaAirport as FcaAirportType,
  FcaArtcc as FcaArtccType,
} from "../../../types";
import restHelpers, { API } from "../../../helpers/restHelpers";

export interface AirspaceFlowProgramMapProps {
  shape: PolygonType | CircleType | LineType | FcaAirportType | undefined;
  typeOfShape: string | undefined;
}

const loadEsriModules = () =>
  loadModules(
    [
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/geometry/Polygon",
      "esri/geometry/Circle",
      "esri/geometry/Polyline",
      "esri/geometry/Point",
    ],
    {
      css: true,
    }
  );

const drawARTCCBoundaries = (Polygon: any, Graphic: any, view: any) => {
  restHelpers.get(API.ArtccBoundaries).then((boundaries: ArtccBoundary[]) => {
    boundaries.forEach((boundary) => {
      const rings = boundary.points.map((point: any) => {
        return [parseFloat(point.longitude), parseFloat(point.latitude)];
      });
      rings.push([parseFloat(boundary.points[0].longitude), parseFloat(boundary.points[0].latitude)]);
      const createdShape = new Polygon({
        rings: rings,
      });

      const artccBoundaryGraphic = new Graphic({
        geometry: createdShape,
        symbol: { type: "simple-line", color: [175, 238, 238], width: 1 },
      });

      const labelGraphic = new Graphic({
        geometry: createdShape.centroid,
        symbol: { type: "text", text: boundary.name, color: [175, 238, 238] },
      });

      view.graphics.add(artccBoundaryGraphic);
      view.graphics.add(labelGraphic);
    });
  });
};

const drawPacingAirports = (Graphic: any, view: any) => {
  restHelpers.get(API.PacingAirports).then((pacingAirports: PacingAirport[]) => {
    pacingAirports.forEach((pacingAirport) => {
      const labelLocation = {
        type: "point",
        latitude: parseFloat(pacingAirport.latitude),
        longitude: parseFloat(pacingAirport.longitude),
      };

      const labelGraphic = new Graphic({
        geometry: labelLocation,
        symbol: { type: "text", text: pacingAirport.airportId, color: [37, 225, 169] },
      });

      view.graphics.add(labelGraphic);
    });
  });
};

const AirspaceFlowProgramMap: React.FC<AirspaceFlowProgramMapProps> = (props: AirspaceFlowProgramMapProps) => {
  const previousShapeRef = useRef<PolygonType | CircleType | LineType | FcaAirportType | undefined | null>(null);
  const mapRef = useRef();

  useEffect(() => {
    if (JSON.stringify(previousShapeRef.current) !== JSON.stringify(props.shape)) {
      loadEsriModules().then(([ArcGISMap, MapView, Graphic, Polygon, Circle, Polyline, Point]) => {
        let createdShape;

        const map = new ArcGISMap({
          basemap: "dark-gray-vector",
        });
        const view = new MapView({
          container: mapRef.current,
          map: map,
          center: [-118, 38],
          zoom: 4,
          navigation: {
            browserTouchPanEnabled: false,
          },
        });

        if (props.typeOfShape === "Polygon") {
          const polygonPoints = (props.shape as PolygonType).points.point;
          const rings = polygonPoints.map((point) => {
            return [parseFloat(point.longitude), parseFloat(point.latitude)];
          });
          rings.push([parseFloat(polygonPoints[0].longitude), parseFloat(polygonPoints[0].latitude)]);

          createdShape = new Polygon({
            rings: rings,
          });

          view.center.latitude = createdShape.centroid.latitude;
          view.center.longitude = createdShape.centroid.longitude;
        }

        if (props.typeOfShape === "Circle") {
          const passedInCircle = props.shape as CircleType;
          createdShape = new Circle({
            center: {
              latitude: parseFloat(passedInCircle.center.latitude),
              longitude: parseFloat(passedInCircle.center.longitude),
            },
            radius: parseInt(passedInCircle.radius),
            radiusUnit: "nautical-miles",
          });

          view.center.latitude = createdShape.centroid.latitude;
          view.center.longitude = createdShape.centroid.longitude;
        }

        if (props.typeOfShape === "Line") {
          const linePoints = (props.shape as LineType).points.point;
          const paths = linePoints.map((point) => {
            return [parseFloat(point.longitude), parseFloat(point.latitude)];
          });

          createdShape = new Polyline({ paths });
          view.center.latitude = paths.reduce((aggregate, path) => path[1] + aggregate, 0) / paths.length;
          view.center.longitude = paths.reduce((aggregate, path) => path[0] + aggregate, 0) / paths.length;
        }

        let symbol;
        if (props.typeOfShape === "FcaAirport" && (props.shape as FcaAirportType).point.latitude !== null) {
          const fcaAirportPoints = (props.shape as FcaAirportType).point;
          createdShape = new Point({
            latitude: parseFloat(fcaAirportPoints.latitude),
            longitude: parseFloat(fcaAirportPoints.longitude),
          });

          symbol = {
            type: "picture-marker",
            width: "20px",
            height: "20px",
            url: "/nasMapSymbols/Nas_Fca_Airport.png",
          };

          view.center.latitude = createdShape.latitude;
          view.center.longitude = createdShape.longitude;
        } else {
          symbol = { type: "simple-line", color: [184, 94, 11], width: 4.0 };
        }

        if (props.typeOfShape === "FcaArtcc" && (props.shape as FcaArtccType).points.point[0].latitude !== null) {
          const fcaArtccPoints = (props.shape as FcaArtccType).points.point;
          const rings = fcaArtccPoints.map((point) => {
            return [parseFloat(point.longitude), parseFloat(point.latitude)];
          });
          rings.push([parseFloat(fcaArtccPoints[0].longitude), parseFloat(fcaArtccPoints[0].latitude)]);

          createdShape = new Polygon({
            rings: rings,
          });

          view.center.latitude = createdShape.centroid.latitude;
          view.center.longitude = createdShape.centroid.longitude;
        }

        if (
          (props.typeOfShape === "FcaAirport" && (props.shape as FcaAirportType).point.latitude === null) ||
          (props.typeOfShape === "FcaArtcc" && (props.shape as FcaArtccType).points.point[0].latitude === null)
        ) {
          view.center = [-97, 38];
          view.zoom = 3.5;
        }

        drawARTCCBoundaries(Polygon, Graphic, view);
        drawPacingAirports(Graphic, view);

        const graphic = new Graphic({
          geometry: createdShape,
          symbol: symbol,
        });

        view.graphics.add(graphic);

        return () => {
          if (view) {
            view.container = null;
          }
        };
      });

      previousShapeRef.current = props.shape;
    }
  }, [props.shape, props.typeOfShape]);

  // @ts-ignore
  return <div className="webmap" title="esri-map" ref={mapRef} />;
};

export default AirspaceFlowProgramMap;
