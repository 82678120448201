import React from "react";
import { FreeForm } from "../../../../types";
import "../airportEventCard.scss";
import { TimeRange } from "../../shared/timerange/TimeRange";

export interface FreeFormBlockProps {
  freeForm: FreeForm;
  showDetails: boolean;
  airportLongName?: string;
}

const FreeFormBlock: React.FC<FreeFormBlockProps> = ({ freeForm, showDetails, airportLongName }) => {
  return (
    <div className="airport-card-block" data-testid={`${freeForm.airportId}-free-form-block`}>
      <div className="airport-card-content">
        <p className="event-type high-severe">Airport Closure</p>
        <TimeRange startTime={freeForm.startTime} endTime={freeForm.endTime} />
      </div>
      {!showDetails && <p>{freeForm.text && `Closed ${freeForm.text}`}</p>}
      {showDetails && (
        <p>
          {" "}
          {airportLongName} is closed {freeForm.text}{" "}
        </p>
      )}
    </div>
  );
};
export default FreeFormBlock;
